import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { Button } from '../ui/Button';
import { SettingsOffIcon } from '../icons/SettingsOffIcon';
import { SettingsOnIcon } from '../icons/SettingsOnIcon';
import { usePlayer } from '../../MeliPlayer.context';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';
import { usePlayerComponents } from '../../MeliPlayerComponents.context';

export const SettingsButton = () => {
  const { SettingsOverlay } = usePlayerComponents();
  const { audioSubtitleSelectorAriaLabel } = useMeliPlayerTranslations();
  const [visible, setVisible] = useState(false);
  const setCanHideControls = usePlayer(
    ({ setCanHideControls: playerSetCanHideControls }) =>
      playerSetCanHideControls,
  );
  const restoreAutoplaySound = usePlayer(
    ({ restoreAutoplaySound: playerRestoreAutoplaySound }) =>
      playerRestoreAutoplaySound,
  );
  const containerNode = usePlayer(
    ({ containerNode: playerContainerNode }) => playerContainerNode,
  );
  const Icon = visible ? SettingsOnIcon : SettingsOffIcon;

  const handleClick = () => {
    restoreAutoplaySound();
    setVisible((open) => !open);
  };

  useEffect(() => {
    setCanHideControls(!visible);
  }, [visible]);

  return (
    <>
      {containerNode.current &&
        createPortal(
          <SettingsOverlay visible={visible} onVisibleChange={setVisible} />,
          containerNode.current,
        )}

      <Button aria-label={audioSubtitleSelectorAriaLabel} onClick={handleClick}>
        <Icon />
      </Button>
    </>
  );
};
