import type {
  FC,
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  PropsWithChildren,
} from 'react';

import classNames from 'classnames';

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button: FC<ButtonProps> = ({ className, ...props }) => (
  <button
    type="button"
    className={classNames('meli-player__button', className)}
    {...props}
  />
);

export const ButtonText: FC<PropsWithChildren> = ({ children }) => (
  <span className="meli-player__button-text">{children}</span>
);
