import type { TagSelectorProps } from '../../../components/TagSelector';

type ValidateInitialFilterProps = {
  filter: string;
  subfilters?: TagSelectorProps['props'];
};

export const validateInitialFilter = ({
  filter,
  subfilters,
}: ValidateInitialFilterProps) => {
  if (subfilters?.tags && subfilters.selected) {
    const { tags, selected } = subfilters;

    return tags[selected].filter;
  }

  return filter;
};
