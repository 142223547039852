import type { FC } from 'react';
import type { SettingButtonProps } from './SettingButton.type';

import { Button } from '../../../Button';
import { Icon } from '../../../Icon';
import { IconName } from '../../../Icon/Icon.type';

export const SettingButton: FC<SettingButtonProps> = ({ className, data }) => {
  if (!data) {
    return null;
  }

  const { href, ...otherProps } = data;

  return (
    <Button
      {...otherProps}
      hierarchy="transparent"
      size="medium"
      to={href}
      className={className}
      leftIcon={
        <Icon content={IconName.SETTINGS} color="var(--andes-accent-color)" />
      }
    />
  );
};
