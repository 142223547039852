import type { FC } from 'react';
import type { TypeKnownComponent } from '../../../../../../types/components';
import type { FeedContainerProps } from '../../../../../components/Feed';

import { FeedContainerHub } from '../../../../../container/FeedContainerHub/desktop';

type FeedProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  [TypeKnownComponent.FEED]?: FeedContainerProps;
};

export const Feed: FC<FeedProps> = ({ feed }) => {
  if (!feed) {
    return null;
  }

  return (
    <FeedContainerHub
      className="hub-desktop__feed"
      components={feed.components}
      tracks={feed.tracks}
      nextPage={feed.nextPage}
    />
  );
};
