import { create } from 'zustand';
import { Tracks } from '../../utils/track';

export type TrackStoreState = {
  mainTracks: Tracks | null;
  setMainTracks: (tracks: Tracks) => void;
};

export const useMainTracksStore = create<TrackStoreState>((set) => ({
  mainTracks: null,
  setMainTracks: (tracks: Tracks) => set(() => ({ mainTracks: tracks })),
}));
