import type { AnchorHTMLAttributes, DetailedHTMLProps, FC } from 'react';

import { useCustomDeeplinkHandler } from '../../utils/webkit/custom-deeplink-handler';

type LinkProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

type DeeplinkProps = LinkProps & {
  callback?: () => void;
};

export const Deeplink: FC<DeeplinkProps> = ({
  href,
  callback = () => null,
  children,
  ...props
}) => {
  const customDeepLinkHandler = useCustomDeeplinkHandler();

  return (
    <a
      href={href}
      onClick={(event) => {
        callback();
        event.preventDefault();

        if (href) {
          customDeepLinkHandler(href);
        }
      }}
      {...props}
    >
      {children}
    </a>
  );
};
