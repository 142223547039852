import type { TagsColorProps } from '../TagSelector.types';

import { useEffect } from 'react';

export const useTagColor = ({
  color,
  borderColor,
  bgColor,
  selectedColor,
  bgSelectedColor,
  borderSelectedColor,
}: TagsColorProps) => {
  useEffect(() => {
    document.documentElement.style.setProperty('--tag-custom-color', color);
    document.documentElement.style.setProperty(
      '--tag-custom-border-color',
      borderColor,
    );
    document.documentElement.style.setProperty(
      '--tag-custom-bg-color',
      bgColor || '',
    );
    document.documentElement.style.setProperty(
      '--tag-custom-color-active',
      selectedColor,
    );
    document.documentElement.style.setProperty(
      '--tag-custom-bg-color-active',
      bgSelectedColor,
    );
    document.documentElement.style.setProperty(
      '--tag-custom-border-color-active',
      borderSelectedColor,
    );
  }, []);
};
