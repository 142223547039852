import { useEffect, useRef, useState } from 'react';

import { PlayerEvent } from '../events.types';
import { usePlayer } from '../MeliPlayer.context';
import { PlayerStatus } from '../MeliPlayer.types';

export const useLayerManagement = () => {
  const on = usePlayer(({ on: playerOn }) => playerOn);
  const canHideControls = usePlayer(
    ({ canHideControls: playerCanHideControls, status }) =>
      playerCanHideControls && status === PlayerStatus.PLAYING,
  );
  const mutedByAutoplayFallback = usePlayer(
    ({ mutedByAutoplayFallback: playerMutedByAutoplayFallback }) =>
      playerMutedByAutoplayFallback,
  );
  const [controlsVisible, setControlsVisible] = useState(false);
  const controlsInactivityTimeout = useRef<NodeJS.Timeout>();

  // By definition, controls should wait 1s before rendering initially
  const [readyToShowControl, setReadyToShowControl] = useState(false);
  const readyToShowControlsTimeout = useRef<NodeJS.Timeout>();

  const stopTrackingInactivity = () => {
    clearTimeout(controlsInactivityTimeout.current);
  };

  const hideControls = () => {
    if (canHideControls) {
      stopTrackingInactivity();
      setControlsVisible(false);
    }
  };

  const startTrackingInactivity = () => {
    clearTimeout(controlsInactivityTimeout.current);
    controlsInactivityTimeout.current = setTimeout(hideControls, 4_300);
  };

  const showControls = () => {
    startTrackingInactivity();
    setControlsVisible(true);
  };

  useEffect(
    () => () => {
      clearTimeout(controlsInactivityTimeout.current);
      clearTimeout(readyToShowControlsTimeout.current);
    },
    [],
  );

  useEffect(() => {
    if (canHideControls) {
      startTrackingInactivity();
    } else {
      stopTrackingInactivity();
    }
  }, [canHideControls]);

  useEffect(() => {
    let readyDispose: () => void;
    let playingDispose: () => void;

    const callback = () => {
      readyDispose();
      playingDispose();
      setReadyToShowControl(true);
    };

    readyDispose = on(PlayerEvent.READY, callback);
    playingDispose = on(PlayerEvent.PLAYING, callback);

    return () => {
      readyDispose();
      playingDispose();
    };
  }, [on]);

  useEffect(() => {
    const dispose = on(PlayerEvent.PLAYING, () => {
      if (!mutedByAutoplayFallback) {
        showControls();
      }
    });

    return () => dispose();
  }, [on, mutedByAutoplayFallback]);

  return {
    readyToShowControl,
    controlsVisible: readyToShowControl && controlsVisible,
    playbackFallbackVisible: readyToShowControl && !controlsVisible,
    showControls,
    hideControls,
  };
};
