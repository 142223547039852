import type { IPlayerAdapter } from '../../MeliPlayer.types';

import { eventEmitter } from '../../utils/eventEmitter';
import { createAdapter } from '../createAdapter';

export type AdapterConfig = unknown;

export const adapter = createAdapter<AdapterConfig>(
  (initialConfig, set, get) => {
    const emitter = eventEmitter(get);

    return {
      ...initialConfig,
      on: emitter.on,
      off: emitter.off,

      setConfig(config) {
        set({ config });
      },

      async load(source) {
        set({ source });

        return Promise.resolve();
      },

      getStatus() {
        return get().status;
      },

      getStreamType() {
        return 'unknown';
      },

      retry() {
        const { load, source } = get();

        if (source) {
          load(source);
        }
      },

      init() {},
      destroy() {},
      play() {},
      restart() {},
      pause() {},
      mute() {},
      unmute() {},
      restoreAutoplaySound() {},
      setVolume() {},
      timeShift() {},
      setViewMode() {},
      seek() {},
      setCurrentTime() {},
      setSeeking() {},
      setCanHideControls() {},
      disableSubtitles() {},
      enableSubtitle() {},
      enableAudio() {},
      getCurrentAudioLang: () => '',
      getCurrentSubtitleLang: () => '',
    } satisfies IPlayerAdapter;
  },
);
