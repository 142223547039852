import type { HttpResponse, HttpResponseBody } from 'bitmovin-player';
import type { AdapterConfig } from '../../components/MeliPlayer/adapters/bitmovin';

import { useMemo } from 'react';

import { usePageConfigurations } from '../../components/Page';
import commons from '../../constants/commons.json';

const { SEEK_TO_END_OFFSET_PLAYER } = commons;

const decodeAmps = (value: HttpResponseBody): HttpResponseBody =>
  typeof value === 'string' ? value.replace(/&amp;/g, '&') : value;

export const usePlayerAdapterConfig = (
  overrides?: Partial<AdapterConfig>,
): AdapterConfig => {
  const { bitmovinLicense, player: globalConfiguration } =
    usePageConfigurations();

  const network = useMemo<AdapterConfig['network']>(
    () => ({
      // eslint-disable-next-line @typescript-eslint/require-await
      async preprocessHttpResponse(type, response: HttpResponse<any>) {
        if (type === 'manifest/dash' && response.body) {
          response.body = Array.isArray(response.body)
            ? response.body.map(decodeAmps)
            : decodeAmps(response.body);
        }

        return response;
      },
    }),
    [],
  );

  return {
    // Is preferred that the player shows an error layer instead of silently failing by rendering nothing.

    key: bitmovinLicense!,

    ...globalConfiguration,
    ...overrides,
    network: {
      ...network,
      ...globalConfiguration?.network,
      ...overrides?.network,
    },
    tweaks: {
      seek_to_end_offset: SEEK_TO_END_OFFSET_PLAYER,
      ...globalConfiguration?.tweaks,
      ...overrides?.tweaks,
    },
  };
};
