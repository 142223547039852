import { usePlayer } from '../../MeliPlayer.context';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';
import { PosterLayer } from './PosterLayer';

export const ErrorLayer = () => {
  const retry = usePlayer(({ retry: playerRetry }) => playerRetry);
  const { errorText, retryButtonLabel } = useMeliPlayerTranslations();

  return (
    <div className="meli-player__error-layer">
      <PosterLayer visible />

      <div className="meli-player__error-layer__content">
        <div className="meli-player__error-layer__text">{errorText}</div>

        <button
          type="button"
          onClick={retry}
          className="meli-player__error-layer__button"
        >
          {retryButtonLabel}
        </button>
      </div>
    </div>
  );
};
