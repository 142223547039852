import type { AdapterStore } from '../adapters/adapters.types';
import type { PlayerEvent, PlayerEventCallback } from '../events.types';

import { useEffect } from 'react';

export function usePlayerEvent<T extends PlayerEvent>(
  player: AdapterStore,
  event: T,
  callback: PlayerEventCallback<T> | undefined,
) {
  useEffect(() => {
    let dispose: (() => void) | undefined;

    if (callback) {
      dispose = player.getState().on(event, callback);
    }

    return () => dispose?.();
  }, [player, callback]);
}
