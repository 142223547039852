import type { FC } from 'react';
import type { TopRightItemProps } from '../MediaCardCompact.types';
import type { IconName } from '../../Icon/Icon.type';

import ButtonAndes from '@andes/button';

import { Icon } from '../../Icon';

export const TopRightItems: FC<TopRightItemProps> = ({
  props,
  onClickMenuOptions,
}) => {
  const { icon } = props;

  return (
    <div className="mediacard-compact__top-right">
      <ButtonAndes
        hierarchy="transparent"
        size="medium"
        {...props}
        onClick={onClickMenuOptions}
      >
        <Icon color="#fff" content={icon as IconName} />
      </ButtonAndes>
    </div>
  );
};
