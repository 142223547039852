import type { FC, SVGProps } from 'react';

export const RewindIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0C7.391 0 3.724 2.623 1.846 6.842V3.333H0v6.82h6.148V8.309H3.241C4.735 4.3 7.915 1.846 12 1.846c5.608 0 10.154 4.546 10.154 10.154S17.608 22.154 12 22.154c-4.977 0-9.118-3.581-9.986-8.308H.14C1.03 19.597 6.001 24 12 24c6.627 0 12-5.373 12-12S18.627 0 12 0Z"
      fill="currentColor"
      fillOpacity={0.9}
    />
    <path
      d="M9.387 15.333h1.17v-6.67h-1.02l-1.92 1.94.68.71 1.09-1.13v5.15Zm2.288-3.33c0 1.68.8 3.45 2.67 3.45 1.87 0 2.67-1.77 2.67-3.45 0-1.68-.8-3.44-2.67-3.44-1.87 0-2.67 1.76-2.67 3.44Zm4.15 0c0 1.27-.39 2.41-1.48 2.41-1.09 0-1.48-1.14-1.48-2.41 0-1.27.39-2.4 1.48-2.4 1.09 0 1.48 1.13 1.48 2.4Z"
      fill="currentColor"
      fillOpacity={0.9}
    />
  </svg>
);
