import type { MediaCardWrapperProps } from '../../MediaCardWrapper.types';

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { HeaderType } from '../../../../../MediaCard/components/Header/Header.types';
import { FeedContext } from '../../../../context';

type InfoSummary = {
  indexInFeed: number | undefined;
  inViewport: Array<number>;
  preloadedIndexes: Array<number>;
  firstPendingIndex: number | undefined;
  priorityIndex: number | undefined;
  preloadBlocked: boolean;
  hasPreview: boolean;
};

const isInViewport = ({ indexInFeed, inViewport }: InfoSummary) =>
  indexInFeed != null && inViewport.includes(indexInFeed);

const isPriority = ({ priorityIndex, indexInFeed }: InfoSummary) =>
  priorityIndex != null && priorityIndex === indexInFeed;

const isNextInPreloadOrder = (info: InfoSummary) =>
  isInViewport(info) &&
  info.firstPendingIndex === info.indexInFeed &&
  !info.preloadBlocked;

const isReadyToPlay = ({ preloadedIndexes, indexInFeed }: InfoSummary) =>
  indexInFeed != null && preloadedIndexes.includes(indexInFeed);

const shouldCreatePlayer = (info: InfoSummary) =>
  info.hasPreview &&
  (isPriority(info) || isReadyToPlay(info) || isNextInPreloadOrder(info));

export const useVideoPreview = ({
  hasPreview,
  indexInFeed,
}: {
  hasPreview: boolean;
  indexInFeed: number | undefined;
}): Partial<MediaCardWrapperProps> => {
  const {
    inViewportIndexes: inViewport,
    preloadedIndexes,
    addPreloadedIndex: markIndexAsPreloaded,
    preloadPriorityIndex: priorityIndex,
    setPreloadPriority: setPriority,
    isPreloadBlocked: preloadBlocked,
  } = useContext(FeedContext);
  const [playVideo, setPlayVideo] = useState(false);

  const firstPendingIndex = useMemo(
    () => inViewport.find((index) => !preloadedIndexes.includes(index)),
    [inViewport, preloadedIndexes],
  );

  const createPlayer = useMemo(
    () =>
      shouldCreatePlayer({
        inViewport,
        indexInFeed,
        firstPendingIndex,
        preloadedIndexes,
        priorityIndex,
        preloadBlocked,
        hasPreview,
      }),
    [
      inViewport,
      indexInFeed,
      firstPendingIndex,
      preloadedIndexes,
      priorityIndex,
      preloadBlocked,
      hasPreview,
    ],
  );

  useEffect(() => {
    if (
      indexInFeed != null &&
      !hasPreview &&
      firstPendingIndex === indexInFeed
    ) {
      markIndexAsPreloaded(indexInFeed);
    }
  }, [firstPendingIndex, indexInFeed]);

  const handleCanPlay = useCallback(() => {
    if (indexInFeed != null) {
      markIndexAsPreloaded(indexInFeed);
    }
  }, [indexInFeed, markIndexAsPreloaded]);

  const handleMouseEnter = useCallback(() => {
    setPriority(indexInFeed);
    setPlayVideo(true);
  }, [indexInFeed, setPriority, setPlayVideo]);

  const handleMouseLeave = useCallback(() => {
    setPriority(undefined);
    setPlayVideo(false);
  }, [setPriority, setPlayVideo]);

  if (hasPreview) {
    return {
      preloadVideo: createPlayer,
      onCanPlay: handleCanPlay,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      currentHeader: playVideo ? HeaderType.ACTIVE : HeaderType.DEFAULT,
    };
  }

  return {
    preloadVideo: false,
  };
};
