import type { FullMediaCardProps } from './FullMediaCard.types';

import Card from '@andes/card';
import classname from 'classnames';

import { Image } from '../../Image/mobile';
import { Link } from '../../Link/Link';

const namespace = 'full-media-card';

const RedirectComponent = ({
  linkTo,
  children,
}: Pick<FullMediaCardProps, 'linkTo'> & { children: React.ReactNode }) =>
  linkTo.pathname?.includes('vivo') ? (
    <a className={`${namespace}__link`} href={linkTo.pathname}>
      {children}
    </a>
  ) : (
    <Link to={linkTo} className={`${namespace}__link`}>
      {children}
    </Link>
  );

export const FullMediaCard = ({ header, linkTo }: FullMediaCardProps) => (
  <Card className={classname(namespace)}>
    <RedirectComponent linkTo={linkTo}>
      <Image carousel preload lazy="off" {...header.default.background.props} />
    </RedirectComponent>
  </Card>
);
