import type { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

import classNames from 'classnames';

export type LayerProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  visible: boolean;
  topShadow?: boolean;
  bottomShadow?: boolean;
};

export const Layer: FC<LayerProps> = ({
  visible,
  children,
  className,
  topShadow = false,
  bottomShadow = false,
  ...props
}) => (
  <div
    {...props}
    className={classNames('meli-player__layer', className, {
      'meli-player__layer--visible': visible,
      'meli-player__layer--top-shadow': topShadow,
      'meli-player__layer--bottom-shadow': bottomShadow,
    })}
  >
    {children}
  </div>
);
