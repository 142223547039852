import type { FeedContainerHookProps } from '../../../components/Feed';
import type { ContextValue } from '../../../pages/hub/context';

import { useCallback, useContext, useEffect, useState } from 'react';

import axios from 'nordic-axios';
import debounce from 'lodash/debounce';
import trimEnd from 'lodash/trimEnd';

import { HubContext } from '../../../pages/hub/context';
import pages from '../../../constants/pages.json';
import { getCarousels, getFeed } from '../repositories';
import {
  getRealFilterTrack,
  ProviderTrack,
  ProviderTrackEventName,
  sendPlatformTracks,
} from '../../../utils/track';
import {
  ApplicationError,
  CustomError,
} from '../../../../services/utils/custom-error';
import { Utils } from '../../../utils';
import { SharedContext } from '../../../context/shared-context/shared.context';
import { useMainTracksStore } from '../../../context/tracks-webview/tracks-webview';
import commons from '../../../constants/commons.json';
import { CUSTOM_NATIVE_EVENTS } from '../../../utils/webkit/custom-native-events';
import { isWebKit2 } from '../../../utils/webkit/check-version';

const { HUB_PAGE_FILTER_LANG } = pages;
const { ALLOWED_CAROUSELS } = commons;
const { BACK_VCP, USER_EVENT } = CUSTOM_NATIVE_EVENTS;

export const useFeedContainer = ({
  feed,
  nextPage,
  isWebview,
}: FeedContainerHookProps) => {
  const {
    filter,
    previousFilter,
    data = feed,
    updateFeed,
    updateFilter,
    setInitialData,
    getSnackbarById,
    nextPage: nextPageContext,
    updateCarouselsComponents,
  } = useContext<ContextValue>(HubContext);
  const context = useContext(SharedContext);
  const { mainTracks } = useMainTracksStore();

  const [isLoading, setIsLoading] = useState(false);

  const fetchFeed = useCallback(async () => {
    try {
      setIsLoading(true);
      getSnackbarById('no-internet-connection-error-snackbar')?.hideSnackbar();
      getSnackbarById('no-load-content-error-snackbar')?.hideSnackbar();

      const hubWithFilter =
        Utils.getArrayValuesFromObject(HUB_PAGE_FILTER_LANG);
      const hubWithFilterByRegex = trimEnd(
        hubWithFilter.join('|'),
        '|',
      ).replace(/\//g, '');
      // eslint-disable-next-line security/detect-non-literal-regexp
      const pathFilterRegex = new RegExp(
        `^(${hubWithFilterByRegex})?\\/?`,
        'i',
      );
      const newFilter = filter?.replace(pathFilterRegex, '');

      const feed = await getFeed({
        filter: newFilter,
        config: {
          singletonId: 'hub-feed',
        },
        ...nextPageContext,
        context,
        isWebview,
      });

      const dataTracks = {
        path:
          feed.tracks?.[ProviderTrackEventName.DISPLAY]?.[
            ProviderTrack.MELIDATA
          ]?.path || '',
        typeEvent:
          feed.tracks?.[ProviderTrackEventName.DISPLAY]?.[
            ProviderTrack.MELIDATA
          ]?.type || 'view',
        eventData: {
          filter: getRealFilterTrack(filter),
          ...feed.tracks?.[ProviderTrackEventName.DISPLAY]?.[
            ProviderTrack.MELIDATA
          ]?.event_data,
        },
        tracks: {
          ...mainTracks,
          ...feed.tracks,
        },
        trackEventName: ProviderTrackEventName.DISPLAY,
        experiments:
          feed.tracks?.[ProviderTrackEventName.DISPLAY]?.[
            ProviderTrack.MELIDATA
          ]?.experiments,
      };

      updateFeed({ data: feed.components, nextPage: feed.nextPage });
      sendPlatformTracks(dataTracks);
      setIsLoading(false);
    } catch (error: unknown) {
      if (axios.isCancel(error)) {
        return;
      }

      let snackbarId = 'no-load-content-error-snackbar';

      if (error instanceof CustomError) {
        if (error.name === ApplicationError.INTERNET_ERROR) {
          snackbarId = 'no-internet-connection-error-snackbar';
        }
      }

      getSnackbarById(snackbarId)?.showSnackbar({
        onActionClick: fetchFeed,
      });

      setIsLoading(false);
    }
  }, [filter, nextPageContext]);

  const updateComponentsCarousels = async () => {
    const response = await getCarousels(ALLOWED_CAROUSELS, context);
    updateCarouselsComponents({ data: response.props.components });
  };

  const subscribeToBackVCPEvent = useCallback(() => {
    if (isWebKit2()) {
      window.MobileWebKit?.events.subscribe(USER_EVENT, (event) => {
        const { args } = event.detail;

        const isValidArgs = typeof args === 'object' && args !== null;
        const isBackVCPEvent =
          isValidArgs && 'action' in args && args.action === BACK_VCP;

        if (isBackVCPEvent) {
          updateComponentsCarousels();
        }
      });

      return;
    }

    window.MobileWebKit?.on('native_request', (evt: unknown) => {
      // @ts-expect-error: not exist type to evt
      if (evt.args.action === BACK_VCP) {
        updateComponentsCarousels();
      }
    });
  }, []);

  useEffect(() => {
    setInitialData({ data: feed, nextPage });
  }, []);

  useEffect(() => {
    if (previousFilter !== filter) {
      fetchFeed();
    }
  }, [previousFilter, filter]);

  useEffect(() => {
    subscribeToBackVCPEvent();
  }, []);

  return {
    feed: data,
    filter,
    onNextPage: debounce(fetchFeed, 50),
    onUpdateFilter: updateFilter,
    isLoading,
    previousFilter,
    hasMore: nextPageContext,
    updateComponentsCarousels,
  };
};
