import type { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

import { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

export type ScrollContainerProps = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'className'
> & {
  className: string;
};

export const ScrollContainer: FC<ScrollContainerProps> = ({
  className,
  children,
  ...props
}) => {
  const [hasTopScroll, setHasTopScroll] = useState(false);
  const [hasBottomScroll, setHasBottomScroll] = useState(false);
  const nodeRef = useRef<HTMLDivElement>(null);

  const updateScrollState = useCallback(() => {
    if (!nodeRef.current) {
      return;
    }

    const node = nodeRef.current;
    const hasContentOnTop = node.scrollTop > 0;
    const hasContentOnBottom =
      node.scrollTop + node.offsetHeight < node.scrollHeight;

    if (hasContentOnTop !== hasTopScroll) {
      setHasTopScroll(hasContentOnTop);
    }

    if (hasContentOnBottom !== hasBottomScroll) {
      setHasBottomScroll(hasContentOnBottom);
    }
  }, [hasTopScroll, hasBottomScroll, setHasTopScroll, setHasBottomScroll]);

  useEffect(() => {
    updateScrollState();
  }, []);

  useEffect(() => {
    const node = nodeRef.current;

    node?.addEventListener('scroll', updateScrollState);

    return () => node?.removeEventListener('scroll', updateScrollState);
  }, [updateScrollState]);

  return (
    <div
      className={classNames('meli-player__scroll-container', className, {
        [`${className}--has-top-scroll`]: hasTopScroll,
        [`${className}--has-bottom-scroll`]: hasBottomScroll,
      })}
      {...props}
    >
      <div className="meli-player__scroll-container__area" ref={nodeRef}>
        {children}
      </div>
    </div>
  );
};
