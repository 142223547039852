import { usePlayer } from '../../MeliPlayer.context';
import { PlayerStatus } from '../../MeliPlayer.types';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';

export const PlaybackBackgroundToggle = () => {
  const { togglePlaybackAriaLabel } = useMeliPlayerTranslations();
  const isPlaying = usePlayer(({ status }) => status === PlayerStatus.PLAYING);
  const play = usePlayer(({ play: playerPlay }) => playerPlay);
  const pause = usePlayer(({ pause: playerPause }) => playerPause);

  const handleClick = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  };

  return (
    <button
      type="button"
      className="meli-player__playback-background"
      onClick={handleClick}
      tabIndex={-1}
      aria-label={togglePlaybackAriaLabel}
    />
  );
};
