import type { FC } from 'react';
import type { LayerProps } from '../ui';

import classNames from 'classnames';
import xss from 'xss';

import { usePlayer } from '../../MeliPlayer.context';
import { Layer } from '../ui';

type SubtitlesLayerProps = LayerProps & {
  controlsVisible: boolean;
};

export const SubtitlesLayer: FC<SubtitlesLayerProps> = ({
  className,
  controlsVisible,
  ...props
}) => {
  const subtitleCues = usePlayer(
    ({ subtitleCues: playerSubtitleCues }) => playerSubtitleCues,
  );
  const viewMode = usePlayer(({ viewMode: setViewMode }) => setViewMode);

  return (
    <Layer
      className={classNames(
        'meli-player__subtitles-layer',
        `meli-player__subtitles-layer--${viewMode.toLocaleLowerCase()}`,
        className,
        {
          'meli-player__subtitles-layer--with-controls': controlsVisible,
        },
      )}
      {...props}
    >
      {subtitleCues.map((cue, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="meli-player__cue"
        >
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cue.html ? xss(cue.html) : cue.text,
            }}
          />
        </div>
      ))}
    </Layer>
  );
};
