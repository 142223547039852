import type { FC, PropsWithChildren } from 'react';
import type { ContextState, ContextValue } from './FeedState.types';

import {
  createContext,
  useReducer,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import FeedReducer, { FeedActions } from '../reducers';

export const initialState: ContextState = {
  isPreloadBlocked: false,
  inViewportIndexes: [],
  preloadedIndexes: [],
  preloadPriorityIndex: undefined,
  previewPlayer: {
    isMuted: true,
  },
};

export const FeedContext = createContext(initialState as ContextValue);

export const FeedProvider: FC<PropsWithChildren<Partial<ContextState>>> = ({
  children,
  inViewportIndexes,
}) => {
  const [state, dispatch] = useReducer(FeedReducer, initialState);

  const toggleAudio = useCallback(() => {
    dispatch({
      type: FeedActions.PREVIEW_PLAYER_TOGGLE_AUDIO,
    });
  }, []);

  const addPreloadedIndex = useCallback((inFeedIndex: number) => {
    dispatch({
      type: FeedActions.PREVIEW_READY_TO_PLAY,
      inFeedIndex,
    });
  }, []);

  const setPreloadPriority = useCallback((inFeedIndex: number | undefined) => {
    dispatch({
      type: FeedActions.SET_PRIORITY_PREVIEW,
      inFeedIndex,
    });
  }, []);

  const memoizedContext = useMemo<ContextValue>(
    () => ({
      ...state,
      toggleAudio,
      addPreloadedIndex,
      setPreloadPriority,
    }),
    [
      state,
      toggleAudio,
      inViewportIndexes,
      addPreloadedIndex,
      setPreloadPriority,
    ],
  );

  useEffect(() => {
    dispatch({
      type: FeedActions.SET_IN_VIEWPORT,
      inViewportIndexes: inViewportIndexes ?? [],
    });
  }, [inViewportIndexes]);

  return (
    <FeedContext.Provider value={memoizedContext}>
      {children}
    </FeedContext.Provider>
  );
};
