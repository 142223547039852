import type { CarouselFreeProps } from '../../../../../components/Carousel';

import { useMemo } from 'react';

import { TypeKnownComponent } from '../../../../../../types/components';
import { Carousel } from '../../../../../components/Carousel';
import { MediaCardVertical } from '../../../../../components/MediaCardVertical/desktop/MediaCardVertical';

export const Ranking: React.FC<CarouselFreeProps> = ({
  components,
  spacing,
  accessibilityLabel,
  mode = 'free',
  carouselType,
  ...props
}) => {
  const memoknownComponents = useMemo(() => {
    const knownComponents: CarouselFreeProps['knownComponents'] = {
      [TypeKnownComponent.VERTICAL_MEDIA_CARD]: (props) => (
        <MediaCardVertical {...props} carouselType={carouselType} />
      ),
    };

    return knownComponents;
  }, []);

  return (
    <article className="feed__item-rating">
      <Carousel
        lazyload
        components={components}
        spacing={spacing}
        accessibilityLabel={accessibilityLabel}
        mode={mode}
        carouselType={carouselType}
        knownComponents={memoknownComponents}
        {...props}
      />
    </article>
  );
};
