import type { FC, RefObject } from 'react';
import type { SliderState } from '@react-stately/slider';

import { useEffect, useRef } from 'react';

import { useSliderThumb } from '@react-aria/slider';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import classNames from 'classnames';
import { VisuallyHidden } from '@andes/common';

export type SliderThumbProps = {
  index: number;
  state: SliderState;
  trackRef: RefObject<HTMLDivElement>;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  label: string;
};

export const SliderThumb: FC<SliderThumbProps> = ({
  state,
  trackRef,
  index,
  label,
  onDragStart,
  onDragEnd,
}) => {
  const inputRef = useRef(null);
  const { thumbProps, inputProps, isDragging } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
    },
    state,
  );

  const { focusProps, isFocusVisible } = useFocusRing();

  useEffect(() => {
    if (isDragging) {
      onDragStart?.();
    } else {
      onDragEnd?.();
    }
  }, [isDragging]);

  return (
    <div
      role="button"
      tabIndex={-1}
      aria-label={label}
      {...thumbProps}
      className={classNames('meli-player__slider__thumb', {
        'meli-player__slider__thumb--focused': isFocusVisible,
        'meli-player__slider__thumb--dragging': isDragging,
      })}
    >
      <VisuallyHidden>
        <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
      </VisuallyHidden>
    </div>
  );
};
