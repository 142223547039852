import type { FC } from 'react';
import type { LayerProps } from '../ui/Layer';

import { loadable, LazyHydrate } from 'nordic/lazy';

import { FullscreenButton } from '../controls/FullscreenButton';
import { VolumeControl } from '../controls/VolumeControl';
import { PlaybackButton } from '../controls/PlaybackButton';
import { ProgressControl } from '../controls/ProgressControl';
import { SeekButton } from '../controls/SeekButton';
import { PlaybackBackgroundToggle } from '../controls/PlaybackBackgroundToggle';
import { Layer } from '../ui/Layer';
import { SettingsButton } from '../controls/SettingsButton';
import { usePlayer } from '../../MeliPlayer.context';
import { PlayerViewMode, StreamTypes } from '../../MeliPlayer.types';
import { FullscreenTitleControl } from '../controls/FullscreenTitleControl';

const InformationButton = loadable(
  async () => import('../controls/live/InformationButton'),
);
const LiveProgressControl = loadable(
  async () => import('../controls/live/LiveProgressControl'),
);

const LiveContinuousPlayList = loadable(
  async () => import('../controls/live/LiveContinuousPlayList'),
);

const FullscreenTitleControlLive = loadable(
  async () => import('../controls/live/FullscreenTitleControlLive'),
);

export type ControlsLayerProps = LayerProps;

export const ControlsLayer: FC<ControlsLayerProps> = (props) => {
  const { isFullscreen, isLive } = usePlayer(
    ({ viewMode, source: playerSource }) => ({
      isFullscreen: viewMode === PlayerViewMode.FULLSCREEN,
      isLive: playerSource?.streamType === StreamTypes.LIVE,
    }),
  );

  return (
    <Layer
      {...props}
      data-testid="controls"
      bottomShadow
      topShadow={isLive || isFullscreen ? true : isFullscreen}
    >
      <PlaybackBackgroundToggle />
      {isFullscreen && !isLive && (
        <div className="meli-player__layout--top">
          <FullscreenTitleControl />
        </div>
      )}
      {isLive && (
        <div className="meli-player__layout--top">
          <LazyHydrate whenIdle>
            <FullscreenTitleControlLive />
          </LazyHydrate>
        </div>
      )}
      <div className="meli-player__layout--bottom">
        <div className="meli-player__layout--col">
          {isLive ? (
            <LazyHydrate whenIdle>
              <LiveProgressControl />
            </LazyHydrate>
          ) : (
            <ProgressControl />
          )}
          <div className="meli-player__layout--row">
            <PlaybackButton />
            <SeekButton shift={-10} />
            <SeekButton shift={10} />
            <VolumeControl />
            <div className="meli-player__layout--filler" />
            {isLive && (
              <LazyHydrate whenIdle>
                <LiveContinuousPlayList />
              </LazyHydrate>
            )}
            <SettingsButton />
            {isLive && (
              <LazyHydrate whenIdle>
                <InformationButton />
              </LazyHydrate>
            )}
            <FullscreenButton />
          </div>
        </div>
      </div>
    </Layer>
  );
};
