export const CUSTOM_NATIVE_EVENTS = {
  CUSTOM_BACK_EVENT: 'custom_back_event',
  FOOTER_EVENT: 'footer_event',
  BACK_VCP: 'back_vcp',
  SEARCH_EVENT: 'search_event',
  USER_EVENT: 'user_event',
};

export const handleNativeFooter = ({ show }: { show: boolean }) => {
  window.MobileWebKit?.executeNative({
    method: CUSTOM_NATIVE_EVENTS.FOOTER_EVENT,
    args: {
      show,
    },
  });
};

export const handleNativeCustomBack = (value: boolean) => {
  window.MobileWebKit?.executeNative({
    method: CUSTOM_NATIVE_EVENTS.CUSTOM_BACK_EVENT,
    args: {
      custom_back: value,
    },
  });
};
