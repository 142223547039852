import type { FC } from 'react';
import type { ContextValue } from '../../pages/hub/context';
import type { TabSelectorProps } from '../../components/TabSelector';
import type { TagsProps } from '../../components/TagSelector';
import type { HubFiltersProps } from './HubFilters.types';

import { useContext, useEffect, useState } from 'react';

import {
  getRealFilterTrack,
  ProviderTrack,
  ProviderTrackEventName,
  sendPlatformTracks,
} from '../../utils/track';
import { HubContext } from '../../pages/hub/context';
import { TabSelector } from '../../components/TabSelector';
import pages from '../../constants/pages.json';
import { TagSelector } from './components/TagSelector';
import { validateInitialFilter } from './utils/validate-filter';
import { useStickyHeader } from '../../components/StickyHeaderWebview/context/StickyHeaderProvider';
import { useMainTracksStore } from '../../context/tracks-webview/tracks-webview';

const { HUB_PAGE } = pages;

export const HubFilters: FC<HubFiltersProps> = ({
  selected = 0,
  tracks,
  behavior,
  tabs,
  ...props
}) => {
  const { handleSubfilters, handleFirstTab } = useStickyHeader();
  const { mainTracks } = useMainTracksStore();

  const { filter, updateFilter, setInitialFilter } =
    useContext<ContextValue>(HubContext);
  const [filterSelected, setFilterSelected] = useState(selected);
  const replaceStateUrl = (filter?: string): void => {
    const filterValue = filter || '';

    let newUrl: string;

    if (!window.MobileWebKit) {
      newUrl = `${HUB_PAGE}${filterValue}`;
    } else {
      const baseUrl = '/webview';

      newUrl = filterValue ? `${baseUrl}?filters=${filterValue}` : baseUrl;
    }

    const currentUrlClean = window.MobileWebKit
      ? window.location.href.split('?')[0] || []
      : window.location.pathname.replace(/\/$/, '');

    if (currentUrlClean !== newUrl) {
      window.history.replaceState({}, '', newUrl);
    }
  };

  const getTabIndexByFilter = (filterSearch?: string) => {
    const tabIndex = tabs.findIndex((filter) => filter.filter === filterSearch);

    return tabIndex !== -1 ? tabIndex : 0;
  };

  const getFilterByIndexTabs = (indexFilter: number) =>
    tabs[parseInt(indexFilter.toString(), 10)];

  const sendTracks = (value: string, index: number) => {
    const dataTracks = {
      path:
        tracks?.[ProviderTrackEventName.CHANGE]?.[ProviderTrack.MELIDATA]
          ?.path || '',
      typeEvent:
        tracks?.[ProviderTrackEventName.CHANGE]?.[ProviderTrack.MELIDATA]
          ?.type || 'view',
      eventData: {
        selected_value: value,
        selected_index: index,
        selection_gesture: 'click',
        filter: getRealFilterTrack(filter),
      },
      tracks: {
        ...tracks,
        ...mainTracks,
      },
      trackEventName: ProviderTrackEventName.CHANGE,
    };

    sendPlatformTracks(dataTracks);
  };

  const handleTabClick: TabSelectorProps['onClick'] = (tab, index) => {
    if (tab?.action) {
      window.MobileWebKit?.navigation.push(tab.action);

      return;
    }

    handleFirstTab(index === 0);
    handleSubfilters(false);
    replaceStateUrl(tab.filter);
    setFilterSelected(getTabIndexByFilter(tab.filter));
    updateFilter({ filter: tab.filter || '' });
    window.scrollTo(0, 0);
    sendTracks(tab.value, index);

    if (tab.secondLevel) {
      handleSubfilters(true);
    }
  };

  useEffect(() => {
    if (tabs[selected].secondLevel && window.MobileWebKit) {
      handleSubfilters(true);
    }
  }, [selected]);

  useEffect(() => {
    const dataTracks = {
      path:
        tracks?.[ProviderTrackEventName.DISPLAY]?.[ProviderTrack.MELIDATA]
          ?.path || '',
      typeEvent:
        tracks?.[ProviderTrackEventName.DISPLAY]?.[ProviderTrack.MELIDATA]
          ?.type || 'view',
      trackEventName: ProviderTrackEventName.DISPLAY,
      tracks: {
        ...tracks,
        ...mainTracks,
      },
    };

    const filterValue = getFilterByIndexTabs(selected);

    if (filterSelected !== selected) {
      setFilterSelected(selected);
    }

    const initialFilter = validateInitialFilter({
      filter: filterValue.filter || '',
      subfilters: filterValue.secondLevel?.props,
    });

    setInitialFilter({ filter: initialFilter! });
    replaceStateUrl(initialFilter);

    sendPlatformTracks(dataTracks);
  }, []);

  const handleChangeSubfilter = (tag: TagsProps, index: number) => {
    updateFilter({ filter: tag.filter! });
    replaceStateUrl(tag.filter);
    sendTracks(tag.value, index);
  };

  return (
    <>
      <TabSelector
        className="filters-container"
        selected={filterSelected}
        behavior={behavior}
        onClick={handleTabClick}
        tabs={tabs}
        {...props}
      />
      <TagSelector
        tagSelector={tabs[filterSelected].secondLevel}
        handleChangeSubfilter={handleChangeSubfilter}
      />
    </>
  );
};
