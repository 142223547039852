import type { FC } from 'react';

import classNames from 'classnames';
import ProgressIndicatorCircular from '@andes/progress-indicator-circular';

import { CloseIcon } from '../../icons/CloseIcon';
import { useMeliPlayerTranslations } from '../../../MeliPlayerTranslations.context';
import { OverlayProps } from '../../../../../container/ContentLiveContainer/type/ComponentLive.types';

export const Overlay: FC<OverlayProps> = ({
  className,
  visible,
  onVisibleChange,
  children,
  loading,
}) => {
  const { closeAriaLabel } = useMeliPlayerTranslations();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames('meli-player__overlay', {
        'meli-player__overlay--visible': visible,
      })}
      onClick={() => onVisibleChange(false)}
    >
      {loading && (
        <div className="meli-player-overlay__loading-container">
          <ProgressIndicatorCircular size="large" />
        </div>
      )}

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(className, {
          'meli-player_overlay--compact': false,
        })}
        onClick={(event) => event.stopPropagation()}
      >
        {visible && (
          <button
            type="button"
            onClick={() => onVisibleChange(false)}
            className="meli-player__overlay-close"
            aria-label={closeAriaLabel}
          >
            <CloseIcon />
          </button>
        )}
        <div className="meli-player__overlay-body">{children}</div>
      </div>
    </div>
  );
};
