import type { FC, SVGProps } from 'react';

export const EnterFullscreenIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 3H3v7m11.25-7H21v7m-6.75 11H21v-7.25M9.25 21H3v-7.25"
      stroke="currentColor"
      strokeOpacity={0.9}
      strokeWidth={1.5}
    />
  </svg>
);
