import type { FC } from 'react';
import type { ErrorWrapperProps } from './ErrorWrapper.types';

import { useMemo } from 'react';

import { ErrorContainer } from '../../container/ErrorContainer';

export const ErrorWrapper: FC<ErrorWrapperProps> = ({
  className = 'mplay-error',
  ...props
}) => {
  const componentsToArray = useMemo(() => {
    props.title.props = {
      ...props.title.props,
      type: 'title',
      size: 'm',
    };

    props.description.props = {
      ...props.description.props,
      type: 'body',
      size: 'm',
    };

    return Object.values(props).filter((value) => value?.type);
  }, [props]);

  return (
    <div>
      <ErrorContainer className={className} components={componentsToArray} />
    </div>
  );
};
