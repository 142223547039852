import type { FC, SVGProps } from 'react';

export const SoundOffIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.443 7.49 1.507 3.557l1.06-1.062 18.878 18.858-1.06 1.061-6.885-6.877v5.971c0 .942-1.184 1.362-1.777.63l-4.577-5.65H1.5v-9h3.943ZM12 14.04v6.058L7.86 14.989H3v-6h3.944L12 14.04Z"
      fill="currentColor"
      fillOpacity={0.9}
    />
    <path
      d="M8.284 6.097 9.35 7.163 12 3.9v5.909l1.5 1.498V2.491c0-.942-1.183-1.362-1.777-.63l-3.44 4.236ZM19.285 17.274l1.072 1.072a10.454 10.454 0 0 0 2.143-6.351v-.011a10.467 10.467 0 0 0-3.076-7.42l-1.06 1.061A8.972 8.972 0 0 1 21 11.99a8.96 8.96 0 0 1-1.715 5.285Z"
      fill="currentColor"
      fillOpacity={0.9}
    />
    <path
      d="M18.209 16.198a7.466 7.466 0 0 0 1.291-4.209c0-2.07-.84-3.946-2.197-5.303l-1.06 1.06A5.981 5.981 0 0 1 18 11.99c0 1.145-.32 2.214-.876 3.124l1.085 1.085Z"
      fill="currentColor"
      fillOpacity={0.9}
    />
  </svg>
);
