import type { FC, PropsWithChildren } from 'react';

import { Children, cloneElement, isValidElement, useEffect } from 'react';

import { usePlayer } from '../../MeliPlayer.context';
import { PlayerStatus } from '../../MeliPlayer.types';
import { useLayerManagement } from '../../hooks/useLayerManagement';
import { LoadingLayer } from '../layers/LoadingLayer';
import { PosterLayer } from '../layers/PosterLayer';
import { PlaybackLayer } from '../layers/PlaybackLayer';
import { usePlayerComponents } from '../../MeliPlayerComponents.context';

export type LayerManagerProps = {
  onControlsShown?: () => void;
  onControlsHidden?: () => void;
  onAutoplayFallbackTransitionEnd?: () => void;
};

export const LayerManager: FC<PropsWithChildren<LayerManagerProps>> = ({
  onControlsShown,
  onControlsHidden,
  onAutoplayFallbackTransitionEnd,
  children,
}) => {
  const { ErrorLayer, EndedLayer } = usePlayerComponents();
  const isShowingAds = usePlayer(
    ({ isShowingAds: showPlayerAds }) => showPlayerAds,
  );
  const isLoading = usePlayer(({ status }) => status === PlayerStatus.LOADING);
  const startOnFullscreen = usePlayer(
    ({ startOnFullscreen }) => startOnFullscreen,
  );

  const isEnded = usePlayer(({ status }) => status === PlayerStatus.ENDED);
  const isPreparing = usePlayer(
    ({ status }) =>
      status === PlayerStatus.IDLE || status === PlayerStatus.LOADING,
  );
  const hasErrors = usePlayer(({ status }) => status === PlayerStatus.ERROR);
  const {
    readyToShowControl,
    controlsVisible,
    playbackFallbackVisible,
    showControls,
    hideControls,
  } = useLayerManagement();

  useEffect(() => {
    if (!readyToShowControl) {
      return;
    }

    if (controlsVisible) {
      onControlsShown?.();
    } else {
      onControlsHidden?.();
    }
  }, [controlsVisible]);

  if (isShowingAds) {
    return null;
  }

  if (isEnded && EndedLayer) {
    return <EndedLayer />;
  }

  if (hasErrors) {
    // eslint-disable
    return <ErrorLayer />;
  }

  if (isPreparing) {
    return (
      <>
        <PosterLayer visible={!startOnFullscreen} />
        <LoadingLayer visible={isLoading} />
      </>
    );
  }

  return (
    <PlaybackLayer
      controlsVisible={controlsVisible}
      playbackFallbackVisible={playbackFallbackVisible}
      showControls={showControls}
      hideControls={hideControls}
      onAutoplayFallbackTransitionEnd={onAutoplayFallbackTransitionEnd}
    >
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { controlsVisible, ...child.props });
        }

        return child;
      })}
    </PlaybackLayer>
  );
};
