import type { FC } from 'react';
import type { ContentSearchProps } from './ContentSearch.type';

import { ContentSearch as BaseContentSearch } from '../../../ContentSearch';

export const ContentSearch: FC<ContentSearchProps> = ({ data, platform }) => {
  if (!data) {
    return null;
  }

  return (
    <BaseContentSearch
      withoutBackArrow
      siteId={platform.siteId}
      siteDomain={platform.domain}
      {...data}
    />
  );
};
