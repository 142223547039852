import type {
  MediaCardCompactComponent,
  CarouselFreeProps,
} from '../../../../../components/Carousel';
import type { MediaCardCompactProps } from '../../../../../components/MediaCardCompact/MediaCardCompact.types';

import { useLayoutEffect, useState } from 'react';

import { Carousel } from '../../../../../components/Carousel';
import { MediaCardCompact } from '../../../../../components/MediaCardCompact';
import { TypeKnownComponent } from '../../../../../../types/components';
import { DialogBox } from '../../../../../components/DialogBox/DialogBox';
import { useKeepWatching } from '../hook/useKeepWatching/useKeepWatching';

export const KeepWatching: React.FC<CarouselFreeProps> = ({
  components,
  spacing,
  accessibilityLabel,
  mode = 'free',
  ...props
}) => {
  const {
    title,
    optionLists,
    loading,
    overlayVisible,
    getMenuOptions,
    onVisibleChange,
    handleSelectOption,
    carouselComponents,
  } = useKeepWatching(components as Array<MediaCardCompactComponent>);
  const [loadCarousel, setLoadCarousel] = useState(false);
  const knownComponents: CarouselFreeProps['knownComponents'] = {
    [TypeKnownComponent.COMPACT_MEDIA_CARD]: ({ contentId, ...props }) => (
      <MediaCardCompact
        lazy="off"
        onClickMenuOptions={async () => getMenuOptions(String(contentId))}
        {...props}
      />
    ),
  };

  const cardsSkeleton = components.slice(0, 8);

  useLayoutEffect(() => {
    setLoadCarousel(true);
  }, []);

  if (loadCarousel) {
    return (
      <article className="feed__item-keepwatching">
        <Carousel
          components={carouselComponents}
          spacing={spacing}
          accessibilityLabel={accessibilityLabel}
          mode={mode}
          {...props}
          knownComponents={knownComponents}
        />
        <DialogBox
          title={title}
          optionsList={optionLists}
          loading={loading}
          visible={overlayVisible}
          onVisibleChange={onVisibleChange}
          handleSelectOption={handleSelectOption}
        />
      </article>
    );
  }

  return (
    <article className="feed__item-keepwatching">
      <div className="feed__item-preloadcards">
        {cardsSkeleton.map((_, index) => {
          const key =
            components[index].props.header.default.background.props.title;

          return (
            <MediaCardCompact
              key={key}
              {...(components[index].props as MediaCardCompactProps)}
            />
          );
        })}
      </div>
    </article>
  );
};
