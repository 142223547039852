import type { FC } from 'react';
import type { SubHeaderProps } from '../../desktop/SubHeader.type';

import { BackButton } from '../BackButton/BackButton';
import { Typography } from '../../../Typography/Typography';

export const PageTitle: FC<Partial<SubHeaderProps>> = ({
  backButton,
  title,
}) => (
  <div className="sub-header-desktop__brand">
    {backButton ? (
      <BackButton className="sub-header-desktop__back-button" {...backButton} />
    ) : null}

    {title ? (
      <Typography
        className="sub-header-desktop__title"
        component="h1"
        label="Mercado Play"
        size="l"
        type="title"
      />
    ) : null}
  </div>
);
