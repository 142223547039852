import type { FC, SVGProps } from 'react';

export const ChevronLeftIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillOpacity={0.9}
      d="m14.066 4.933 1.06 1.06-6.004 6.004 6.01 6.01-1.06 1.06-7.07-7.07 7.064-7.064Z"
    />
  </svg>
);
