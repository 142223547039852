import type { FC } from 'react';
import type { FeedContentFinishedProps } from './FeedContentFinished.types';

import { Typography } from '../Typography/Typography';
import { Icon } from '../Icon';

export const FeedContentFinished: FC<FeedContentFinishedProps> = ({
  title,
  leftContent,
}) => (
  <div className="feed-content__finished">
    <Icon
      className="feed-content__finished-relevant-icon"
      {...leftContent.props}
    />
    <Typography
      size="l"
      className="feed-content__finished-title"
      {...title.props}
    />
  </div>
);
