import type { FC } from 'react';

import { usePlayer } from '../../MeliPlayer.context';
import { PlayerViewMode } from '../../MeliPlayer.types';
import { Button } from '../ui';
import { ChevronLeftIcon } from '../icons/ChevronLeftIcon';
import { Image } from '../../../Image/mobile';

export const FullscreenTitleControl: FC = () => {
  const source = usePlayer(({ source: playerSource }) => playerSource);
  const setViewMode = usePlayer(
    ({ setViewMode: setPlayerViewMode }) => setPlayerViewMode,
  );

  const handleClick = () => {
    setViewMode(PlayerViewMode.INLINE);
  };

  return (
    <div className="meli-player__fullscreen-title">
      <Button
        className="meli-player__fullscreen-title__button"
        onClick={handleClick}
        aria-label="Exit fullscreen"
      >
        <ChevronLeftIcon />
      </Button>

      {source && (
        <div className="meli-player__fullscreen-title__info">
          {source.logo && (
            <div className="meli-player__fullscreen-logo">
              <Image
                lazy="off"
                className="mplay-player__fullscreen-logo__img"
                {...source.logo.props}
              />
            </div>
          )}
          {source.title && (
            <div className="meli-player__fullscreen-title__title">
              {source.title}
            </div>
          )}

          {source.secondaryTitle && (
            <div className="meli-player__fullscreen-title__secondary-title">
              {source.secondaryTitle}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
