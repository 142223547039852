import type { FC } from 'react';
import type { HubFiltersProps } from '../../../../../container/HubFilters';

import { HubFilters } from '../../../../../container/HubFilters';
import { TabBehavior } from '../../../../../components/TabSelector';

type TabSelectorProps = {
  tabSelector?: HubFiltersProps;
};

export const TabSelector: FC<TabSelectorProps> = ({ tabSelector }) => {
  if (!tabSelector) {
    return null;
  }

  return (
    <HubFilters
      className="hub-desktop__header--tab-selector"
      classNameTabs="tabs tabs-scrollable"
      fadeOutColor="#f5f5f5"
      behavior={TabBehavior.leftAligned}
      {...tabSelector}
    />
  );
};
