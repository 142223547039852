import type { FC, SVGProps } from 'react';

export const SoundOnIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.861 14.99 12 20.097V3.9L7.868 8.99H3v6h4.861Zm-.708-7.5H1.5v9h5.646l4.577 5.649c.593.732 1.777.312 1.777-.63V2.491c0-.942-1.183-1.362-1.777-.63l-4.57 5.628ZM19.433 19.438a10.467 10.467 0 0 0 3.067-7.416c0-2.907-1.181-5.537-3.089-7.439l-1.06 1.061A8.972 8.972 0 0 1 21 12.021a8.971 8.971 0 0 1-2.63 6.358l1.063 1.059Z"
      fill="currentColor"
      fillOpacity={0.9}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.329 17.3a7.476 7.476 0 0 0 2.171-5.278c0-2.08-.847-3.963-2.215-5.322l-1.064 1.057A5.982 5.982 0 0 1 18 12.022a5.98 5.98 0 0 1-1.724 4.21l1.053 1.067Z"
      fill="currentColor"
      fillOpacity={0.9}
    />
  </svg>
);
