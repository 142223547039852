import type { FC, PropsWithChildren } from 'react';

import { usePlayer } from '../../MeliPlayer.context';
import { usePlayerComponents } from '../../MeliPlayerComponents.context';
import { AutoplayUnmuteButton } from '../controls/AutoplayUnmuteButton';
import { LoadingLayer } from './LoadingLayer';
import { SubtitlesLayer } from './SubtitlesLayer';

export type PlaybackLayerProps = {
  controlsVisible: boolean;
  playbackFallbackVisible: boolean;
  showControls: () => void;
  hideControls: () => void;
  onAutoplayFallbackTransitionEnd?: () => void;
};

export const PlaybackLayer: FC<PropsWithChildren<PlaybackLayerProps>> = ({
  controlsVisible,
  playbackFallbackVisible,
  showControls,
  hideControls,
  onAutoplayFallbackTransitionEnd,
  children,
}) => {
  const { ControlsLayer } = usePlayerComponents();
  const stalling = usePlayer(({ stalling: playerStalling }) => playerStalling);
  const mutedByAutoplayFallback = usePlayer(
    ({ mutedByAutoplayFallback: playerMutedByAutoplayFallback }) =>
      playerMutedByAutoplayFallback,
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <div
      data-testid="layer-manager"
      className="meli-player__layer-manager"
      onFocus={showControls}
      onMouseMove={showControls}
      onMouseLeave={hideControls}
      onClick={showControls}
      role="region"
    >
      <LoadingLayer visible={stalling} iconOnly />

      {mutedByAutoplayFallback && (
        <AutoplayUnmuteButton
          visible={playbackFallbackVisible}
          onTransitionEnd={onAutoplayFallbackTransitionEnd}
        />
      )}

      <SubtitlesLayer visible controlsVisible={controlsVisible} />
      <ControlsLayer visible={controlsVisible} />

      {children}
    </div>
  );
};
