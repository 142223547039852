import type { ElementType } from 'react';

export enum ScrollDirection {
  'NO' = 'SCROLL_NO',
  'UP' = 'SCROLL_UP',
  'DOWN' = 'SCROLL_DOWN',
}

export type StickyScrollContainerProps = {
  readonly className?: string;
  children: Array<JSX.Element> | JSX.Element | string;
  component: ElementType;
};
