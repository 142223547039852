import type { DesktopComponents, PageProps } from '../../../components/Page';
import type { SnackbarProps } from '../../../components/Snackbar';

import { useEffect, type FC } from 'react';

import {
  ComponentButtonId,
  TypeKnownComponent,
} from '../../../../types/components';
import { SPAPage } from '../../../components/Page';
import { I18nProvider } from '../../../context';
import { StickyScrollContainer } from '../../../components/StickyScrollContainer';
import { SubHeader } from '../../../components/SubHeader';
import { HubProvider } from '../context/HubState';
import { Feed, TabSelector, Snackbar } from './components';
import { TracksProvider } from '../../../context/tracks-context/tracks.context';
import { LoadingOverlay } from './components/LoadingOverlay';
import { useMainTracksStore } from '../../../context/tracks-webview/tracks-webview';

const Hub: FC<PageProps<DesktopComponents>> = ({
  device,
  headerOptions,
  platform,
  translations,
  view: { components },
  locale,
  view,
  googleTagManager,
}) => {
  const { setMainTracks } = useMainTracksStore();

  useEffect(() => {
    if (view.tracks) {
      setMainTracks(view.tracks);
    }
  }, []);

  return (
    <HubProvider>
      <TracksProvider allowFilterOnTracks>
        <I18nProvider>
          <SPAPage
            name="app-desktop"
            chunkName="hub-desktop"
            className="hub-desktop"
            view={view}
            device={device}
            platform={platform}
            headerOptions={headerOptions}
            translations={translations}
            locale={locale}
            googleTagManager={googleTagManager}
          >
            <StickyScrollContainer
              className="hub-desktop__header"
              component="header"
            >
              <SubHeader
                title
                contentSearch={{
                  data: components[TypeKnownComponent.CONTENT_SEARCH],
                  platform,
                }}
                settingsButton={{
                  data: components[ComponentButtonId.SettingsButton],
                }}
              />
              <TabSelector
                tabSelector={components[TypeKnownComponent.TAB_SELECTOR]}
              />
            </StickyScrollContainer>

            <section className="hub-desktop__content">
              <Feed feed={components[TypeKnownComponent.FEED]} />
              {components[TypeKnownComponent.SNACKBAR]?.map(
                ({ id, text, ...props }: SnackbarProps) => (
                  <Snackbar
                    id={id}
                    classNames="hub-desktop__snackbar"
                    key={id}
                    text={text || ''}
                    {...props}
                  />
                ),
              )}
            </section>
            <LoadingOverlay />
          </SPAPage>
        </I18nProvider>
      </TracksProvider>
    </HubProvider>
  );
};

export default Hub;
