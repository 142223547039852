import type { FC } from 'react';
import type { CarouselFreeProps } from '../../../../../components/Carousel/Carousel.type';

import { memo } from 'react';

import { Ranking } from '../../../components/Ranking/desktop';
import { Vertical } from '../../../components/Vertical/desktop';
import { MainSlider } from '../../../components/MainSlider/desktop';
import { useSlidesPerView } from './hooks/useSlidesPerView';
import { KeepWatching } from '../../../components/KeepWatching/desktop';
import { TypeKnownComponent } from '../../../../../../types/components';

export const CarouselSnapped: FC<CarouselFreeProps> = memo((props) => {
  const { components: allComponents, carouselType } = props;
  const { slides } = useSlidesPerView();

  const isKeepWatching = allComponents.find(
    (card) => card.type === TypeKnownComponent.COMPACT_MEDIA_CARD,
  );

  if (carouselType === 'ranking') {
    return (
      <Ranking
        slidesPerView={8}
        carouselType={carouselType}
        arrows={{ size: 'small' }}
        pagination={false}
        mode="snapped"
        components={allComponents}
      />
    );
  }

  if (isKeepWatching || carouselType === 'keepwatching') {
    return (
      <KeepWatching
        mode="snapped"
        pagination={false}
        slidesPerView={slides}
        arrows={{ size: 'small' }}
        {...props}
      />
    );
  }

  if (carouselType === 'main_slider') {
    return <MainSlider components={allComponents} />;
  }

  return (
    <Vertical
      slidesPerView={8}
      arrows={{ size: 'small' }}
      pagination={false}
      mode="snapped"
      components={allComponents}
      carouselType={carouselType}
      className={
        carouselType === 'live' ? 'feed__item-live' : 'feed__item-vertical'
      }
    />
  );
});

CarouselSnapped.displayName = 'CarouselSnapped';
