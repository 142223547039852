import type { MeliPlayerTranslations } from './MeliPlayer.types';

import { createContext, useContext } from 'react';

export const MeliPlayerTranslationsContext = createContext<
  Partial<MeliPlayerTranslations>
>({});

export const useMeliPlayerTranslations = () =>
  useContext(MeliPlayerTranslationsContext);
