import type { MelidataTrack } from './providers/melidata';

export enum ProviderTrack {
  MELIDATA = 'melidata',
  REAL_TIME = 'real_time',
  PLAYBACK_HISTORY = 'playback_history',
}

export const enum ProviderTrackEventName {
  CHANGE = 'change',
  DISPLAY = 'display',
  READY = 'ready',
  PAIRING = 'pairing',
  PLAYBACK_END = 'playback_end',
  PLAYBACK_PAUSE = 'playback_pause',
  PLAYBACK_PROGRESS = 'playback_progress',
  PLAYBACK_RESUME = 'playback_resume',
  PLAYBACK_START = 'playback_start',
  PLAYBACK_FORWARD = 'playback_forward',
  PLAYBACK_REWIND = 'playback_rewind',
  PLAYBACK_SCRUB = 'playback_scrub',
  PLAYBACK_AD_START = 'playback_ad_start',
  PLAYBACK_AD_END = 'playback_ad_end',
  PLAYBACK_AD_PAUSE = 'playback_ad_pause',
  PLAYBACK_AD_RESUME = 'playback_ad_resume',
  PLAYBACK_AD_SKIP = 'playback_ad_skip',
  LOGIN_USER_GUEST = 'login_user_guest',
  PLAYBACK_CONTINUOUS = 'playback_continuous',
  ACTION = 'action',
  SELECT = 'select',
}

export type Track = {
  [ProviderTrack.MELIDATA]?: MelidataTrack;
  [ProviderTrack.REAL_TIME]?: MelidataTrack;
  [ProviderTrack.PLAYBACK_HISTORY]?: Record<string, string>;
};

export type Tracks = Partial<Record<ProviderTrackEventName, Track>>;

export type TrackProviderType = Record<
  string,
  (...props: Array<unknown>) => void
>;

export type OptionsTrack = {
  withViewTrack?: boolean;
};
