import type { FC } from 'react';
import type { LayerProps } from '../ui';

import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';
import classNames from 'classnames';

import { Layer } from '../ui';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';

type LayerPropsProps = LayerProps & {
  iconOnly?: boolean;
};

export const LoadingLayer: FC<LayerPropsProps> = ({
  className,
  iconOnly = false,
  ...props
}) => {
  const { loading } = useMeliPlayerTranslations();

  return (
    <Layer
      data-testid="loading-layer"
      className={classNames('meli-player__loading-layer', className)}
      bottomShadow={!iconOnly}
      {...props}
    >
      <ProgressIndicatorCircular color="white" size="xlarge" />

      {!iconOnly && (
        <div className="meli-player__loading-layer__text">{loading}</div>
      )}
    </Layer>
  );
};
