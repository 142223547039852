import type { FC } from 'react';

import { usePlayer } from '../../MeliPlayer.context';
import { ForwardIcon } from '../icons/ForwardIcon';
import { RewindIcon } from '../icons/RewindIcon';
import { Button } from '../ui/Button';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';
import { StreamTypes } from '../../MeliPlayer.types';

export type SeekButtonProps = {
  shift: number;
};

export const SeekButton: FC<SeekButtonProps> = ({ shift }) => {
  const timeShift = usePlayer(
    ({ timeShift: playerTimeShift }) => playerTimeShift,
  );

  const { forwardAriaLabel, rewindAriaLabel } = useMeliPlayerTranslations();
  const isLive = usePlayer(
    ({ source }) => source?.streamType === StreamTypes.LIVE,
  );

  const { Icon, label } =
    shift > 0
      ? { Icon: ForwardIcon, label: forwardAriaLabel }
      : { Icon: RewindIcon, label: rewindAriaLabel };

  const handleClick = () => {
    timeShift(shift);
  };

  if (isLive) {
    return null;
  }

  return (
    <Button aria-label={label} onClick={handleClick}>
      <Icon />
    </Button>
  );
};
