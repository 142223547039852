import type { FC } from 'react';
import type { ProgressIndicatorProps } from '../../../ProgressIndicator/ProgressIndicator.type';

import { useSmoothDragSeek } from '../../hooks/useSmoothDragSeek';
import { usePlayer } from '../../MeliPlayer.context';
import { Slider } from '../ui/Slider';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';
import { ProgressIndicator } from '../../../ProgressIndicator';
import { ProgressBarType } from '../../../MediaCard/components/MediaCardFull/MediaCardFull.types';

export type ProgressBarProps = ProgressIndicatorProps & {
  disabled?: boolean;
  showBuffer?: boolean;
};

export const ProgressBar: FC<ProgressBarProps> = (props) => {
  const {
    disabled,
    showBuffer = true,
    className,
    progress,
    ...progressIndicatorProps
  } = props;
  const { playbackProgressAriaLabel } = useMeliPlayerTranslations();
  const seek = usePlayer(({ seek: playerSeek }) => playerSeek);

  const currentTime = usePlayer(
    ({ currentTime: playerCurrentTime }) => playerCurrentTime ?? 0,
  );
  const maxBufferedTime = usePlayer(
    ({ maxBufferedTime: playerMaxBufferedTime }) => playerMaxBufferedTime,
  );
  const totalDuration = usePlayer(
    ({ totalDuration: playerTotalDuration }) => playerTotalDuration ?? 0,
  );
  const { onDragStart, onDragEnd } = useSmoothDragSeek();
  const setCurrentTime = usePlayer(
    ({ setCurrentTime: playerSetCurrentTime }) => playerSetCurrentTime,
  );

  return progress === ProgressBarType.FULL ? (
    <ProgressIndicator
      className={className}
      currentPercentage={100}
      color={undefined}
      {...progressIndicatorProps}
    />
  ) : (
    <Slider
      disabled={disabled}
      className={className}
      minValue={0}
      maxValue={totalDuration}
      value={currentTime}
      secondaryValue={showBuffer ? maxBufferedTime : undefined}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onChange={(value) => {
        setCurrentTime(Array.isArray(value) ? value[0] : value);
      }}
      onChangeEnd={(value) => {
        seek(Array.isArray(value) ? value[0] : value);
      }}
      label={playbackProgressAriaLabel || ''}
    />
  );
};
