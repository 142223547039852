import type { FC, SVGProps } from 'react';

export const ExitFullscreenIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.703 3v6.052H3M15.298 3v6.052H21M15.298 21v-6.052H21M8.704 21v-6.052H3.001"
      stroke="currentColor"
      strokeOpacity={0.9}
      strokeWidth={1.5}
    />
  </svg>
);
