export const SnackbarMapColor = new Map<string, 'green' | 'red'>([
  ['success', 'green'],
  ['error', 'red'],
]);

export type SnackbarProps = {
  id?: string;
  text: string;
  type?: 'error' | 'success';
  duration?: 'normal';
  show?: boolean;
  classNames?: string;
  actionPosition?: 'bottom' | 'right';
  onActionClick?: () => void;
  action?: {
    props: { label?: string };
  };
};
