import { usePlayer } from '../../MeliPlayer.context';
import { PlayerViewMode } from '../../MeliPlayer.types';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';
import { EnterFullscreenIcon } from '../icons/EnterFullscreenIcon';
import { ExitFullscreenIcon } from '../icons/ExitFullscreenIcon';
import { Button } from '../ui/Button';

export const FullscreenButton = () => {
  const isFullscreen = usePlayer(
    ({ viewMode }) => viewMode === PlayerViewMode.FULLSCREEN,
  );
  const { enterFullscreenAriaLabel, exitFullscreenAriaLabel } =
    useMeliPlayerTranslations();

  const setViewMode = usePlayer(
    ({ setViewMode: setPlayerViewMode }) => setPlayerViewMode,
  );

  const Icon = isFullscreen ? ExitFullscreenIcon : EnterFullscreenIcon;
  const label = isFullscreen
    ? exitFullscreenAriaLabel
    : enterFullscreenAriaLabel;

  const handleClick = () => {
    if (isFullscreen) {
      setViewMode(PlayerViewMode.INLINE);
    } else {
      setViewMode(PlayerViewMode.FULLSCREEN);
    }
  };

  return (
    <Button aria-label={label} onClick={handleClick}>
      <Icon />
    </Button>
  );
};
