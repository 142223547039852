import { useEffect, useRef } from 'react';

export function useMemoCompare<T>(
  value: T,
  compare: (prev: T | undefined, next: T) => boolean,
): T {
  const previousRef = useRef<T>(value);
  const previous = previousRef.current;
  const isEqual = compare(previous, value);

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = value;
    }
  });

  return isEqual ? previous : value;
}
