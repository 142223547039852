import type { Tracks } from '../../utils/track';
import type { TagSelectorProps } from '../TagSelector/TagSelector.types';

export type TabSelectorProps = {
  accessibilityLabel?: string;
  behavior?: TabBehavior;
  className?: string;
  classNameTabs?: string;
  fadeOutColor?: string;
  fullWidth?: boolean;
  leftAligned?: boolean;
  selected?: number;
  tabs: Array<TabProps>;
  tracks?: Tracks;
  onClick?: (tab: TabProps, index: number) => void;
};

export type TabProps = TabsValueProps & {
  secondLevel?: TagSelectorProps;
};

// TODO: challengear nombre por uno mejor
export type TabsValueProps = {
  filter?: string;
  label: string;
  value: string;
  action?: string;
};

export enum TabBehavior {
  leftAligned = 'leftAligned',
  fullWidth = 'fullWidth',
}

export type TabSkeletonProps = {
  animation?: boolean;
  tabs?: number;
};
