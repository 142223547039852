import type { FC, MouseEventHandler } from 'react';
import type { MediaCardVideoPlayerProps } from './MediaCardVideoPlayer.types';
import type { MeliPlayerRef } from '../../../MeliPlayer';
import type { ComponentMapperProps } from '../../../ComponentMapper';
import type { ProgressBarProps } from '../../../MeliPlayer/components/controls';

import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classnames from 'classnames';

import { FeedContext } from '../../../Feed/context';
import { MeliPlayer } from '../../../MeliPlayer';
import { usePageConfigurations } from '../../../Page';
import { AudioControl } from './components/AudioControl';
import { ProgressBar } from '../../../MeliPlayer/components/controls';
import { usePerformanceEvents } from '../../../../container/MeliPlayerContainer/hooks/usePerformanceEvents';
import { ProviderTrackEventName, track } from '../../../../utils/track';
import { mergeListeners } from '../../../../container/MeliPlayerContainer/utils/mergeListeners';
import { usePlayerAdapterConfig } from '../../../../hooks/usePlayerAdapterConfig';
import { i18nContext } from '../../../../context';
import { ComponentMapper } from '../../../ComponentMapper';
import { TypeKnownComponent } from '../../../../../types/components';

// Esta validación es para definir `self` globalmente en todo el entorno,
// sin esto, detecta un ReferenceError en la consola al momento de ejecutar BitmovinAnalytics
// eslint-disable-next-line no-restricted-globals
if (typeof self === 'undefined') {
  global.self = global as Window & typeof globalThis;
}

const adapter = import(
  /* webpackChunkName: "meliplayer.adapter" */ '../../../MeliPlayer/adapters/bitmovin'
).then((result) => result.adapter);

const componentsList: ComponentMapperProps['components'] = {
  [TypeKnownComponent.SEEK_BAR]: (props: ProgressBarProps) => (
    <ProgressBar
      className="media-card-video-player__progress"
      disabled
      showBuffer={false}
      {...props}
    />
  ),
};

export const MediaCardVideoPlayer: FC<MediaCardVideoPlayerProps> = memo(
  ({
    className,
    playbackContext,
    onReady,
    onPlaying,
    onPlaybackFinished,
    playing,
    playVideo = false,
    tracks,
    ui,
  }: MediaCardVideoPlayerProps) => {
    const playerRef = useRef<MeliPlayerRef<unknown>>(null);
    const { playbackConfig, stats } = usePageConfigurations();
    const playerAdapterConfig = usePlayerAdapterConfig();
    const { player: playerTranslations } = useContext(i18nContext);
    const [isReady, setIsReady] = useState(false);

    const resultComponents = useMemo(
      () =>
        ui?.controls?.filter(({ type }) =>
          Object.keys(componentsList).includes(type),
        ),
      [ui?.controls, componentsList],
    );

    const performanceEvents = usePerformanceEvents(
      (data) => {
        track(
          tracks,
          ProviderTrackEventName.READY,
          {
            player_init_duration: data.playerInitDuration,
            metadata_load_duration: data.metadataLoadDuration,
            prebuffer_duration: data.prebufferDuration,
            total_duration: data.totalDuration,
            source: data.source,
            version: stats?.version,
          },
          { withViewTrack: false },
        );
      },
      [playbackContext],
    );

    const {
      previewPlayer: { isMuted },
      toggleAudio,
    } = useContext(FeedContext);

    const handleAudioClick: MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        event.preventDefault();
        toggleAudio();
      },
      [toggleAudio],
    );

    useEffect(() => {
      if (!isReady) {
        return;
      }

      const player = playerRef.current?.get();

      if (playVideo) {
        player?.play();
      } else {
        player?.pause();
        player?.seek(0);
      }
    }, [playVideo, isReady]);

    if (!playerAdapterConfig.key) {
      return null;
    }

    return (
      <MeliPlayer
        ref={playerRef}
        className={classnames('media-card-video-player', className)}
        adapter={adapter}
        adapterConfig={{
          ...playerAdapterConfig,
          adaptation: {
            limitToPlayerSize: true,
            ...playerAdapterConfig.adaptation,
          },
        }}
        config={{
          muted: isMuted,
          autoplay: false,
          defaultAudioTrack: playbackConfig?.audioLanguages,
        }}
        source={playbackContext.sources}
        translations={playerTranslations}
        {...mergeListeners(
          performanceEvents,
          {
            onReady,
            onPlaying,
            onPlaybackFinished,
          },
          {
            onReady: () => setIsReady(true),
          },
        )}
      >
        {playing && (
          <>
            <AudioControl
              className="media-card-video-player__audio-control"
              isMuted={isMuted}
              onClick={handleAudioClick}
            />
            {resultComponents ? (
              resultComponents.map((component) => (
                <ComponentMapper {...component} components={componentsList} />
              ))
            ) : (
              <ProgressBar
                className="media-card-video-player__progress"
                disabled
                showBuffer={false}
              />
            )}
          </>
        )}
      </MeliPlayer>
    );
  },
);
