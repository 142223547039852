import type { MeliPlayerListeners } from '../../../components/MeliPlayer';

export const mergeListeners = (
  ...listenersGroups: Array<MeliPlayerListeners>
): MeliPlayerListeners => {
  const keys = Array.from(
    new Set(
      listenersGroups
        // eslint-disable-next-line array-func/prefer-flat-map
        .map((listenersGroup) => Object.keys(listenersGroup))
        .flat(),
    ),
  ) as Array<keyof MeliPlayerListeners>;

  return keys.reduce<MeliPlayerListeners>(
    (result, prop) => ({
      ...result,
      [prop]: (event: never) => {
        listenersGroups.forEach((listenersGroup) => {
          listenersGroup[prop]?.(event);
        });
      },
    }),
    {},
  );
};
