import type { FC } from 'react';
import type { SubHeaderProps } from './SubHeader.type';

import { ContentSearch } from '../components/ContentSearch';
import { SettingButton } from '../components/SettingButton';
import { PageTitle } from '../components/PageTitle/PageTitle';

export const SubHeader: FC<SubHeaderProps> = (props) => {
  const { contentSearch, settingsButton, ...pageTitleProps } = props;

  return (
    <section className="sub-header-desktop">
      <PageTitle {...pageTitleProps} />

      <span className="sub-header-desktop__vertical-divider" />
      <ContentSearch {...contentSearch} />
      <SettingButton className="sub-header-desktop__icon" {...settingsButton} />
    </section>
  );
};
