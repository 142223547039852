import type { MediaCardCompactProps } from './MediaCardCompact.types';
import type { FC } from 'react';

import classname from 'classnames';
import Card from '@andes/card';

import { Link } from '../Link';
import { Image } from '../Image/mobile';
import { Typography } from '../Typography/mobile';
import { ProgressIndicator } from '../ProgressIndicator';
import { Icon } from '../Icon';
import { PaddingSizeDefault } from '../MediaCard/components/MediaCardFull/MediaCardFull.types';
import { BottomLeftWrapper } from './components/BottomLeftItems';
import { TopRightItems } from './components/TopRightItems';

export const MediaCardCompact: FC<MediaCardCompactProps> = ({
  shadow = 'elevated',
  paddingSize = PaddingSizeDefault,
  outline = 'default',
  linkTo,
  header,
  lazy,
  onClickMenuOptions = () => null,
}) => {
  const {
    background,
    bottomLeftItem,
    bottomLeftItems,
    elapsedPercentage,
    topRightItem,
  } = header.default;
  const showLabelPlaying = background
    ? Boolean(background.props.ui?.headerOverlay)
    : false;

  return (
    <Card
      shadow={shadow}
      paddingSize={paddingSize}
      className={classname('mediacard-compact', {
        'mediacard-compact--outline-list': outline === 'list',
      })}
    >
      {topRightItem && (
        <TopRightItems
          {...topRightItem}
          onClickMenuOptions={onClickMenuOptions}
        />
      )}
      <Link to={linkTo}>
        <div className="mediacard-compact__body">
          {background ? (
            <div className="mediacard-compact__body--image">
              <Image lazy={lazy} ratio="16_9" {...background.props} />

              {background.props.ui?.headerOverlay ? (
                <div className="mediacard-compact__body--with-centered-label ">
                  <Typography
                    maxLines={1}
                    size="s"
                    type="body"
                    component="p"
                    color="inverted"
                    label={background.props.ui.headerOverlay.props.label}
                  />
                </div>
              ) : null}
            </div>
          ) : null}

          <BottomLeftWrapper
            isVisible={!showLabelPlaying}
            item={bottomLeftItem}
            items={bottomLeftItems}
          />

          {!showLabelPlaying && elapsedPercentage ? (
            <ProgressIndicator
              className="progress-indicator__bottom progress-indicator__bottom--card"
              currentPercentage={elapsedPercentage}
            />
          ) : null}

          {background && background.props.ui?.headerOverlayIcon ? (
            <div className="mediacard-compact__container-icon">
              <Icon
                {...background.props.ui.headerOverlayIcon.props}
                className="mediacard-compact__icon-play"
              />
            </div>
          ) : null}
        </div>
      </Link>
    </Card>
  );
};
