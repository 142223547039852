import type { StateCreator } from 'zustand';
import type { IPlayerAdapter } from '../MeliPlayer.types';
import type { AdapterInitialConfig } from './adapters.types';

import { create } from 'zustand';

type PrependParameters<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends (...args: Array<any>) => unknown,
  P extends [...args: Array<unknown>],
> = (...args: [...P, ...Parameters<F>]) => ReturnType<F>;

export function createAdapter<T>(
  initializer: PrependParameters<
    StateCreator<IPlayerAdapter<T>>,
    [initialConfig: AdapterInitialConfig<T>]
  >,
) {
  return (initialConfig: AdapterInitialConfig<T>) =>
    create<IPlayerAdapter<T>>((set, get, store) =>
      initializer(initialConfig, set, get, store),
    );
}
