import type { FC, PropsWithChildren } from 'react';
import type { ContextState, ContextValue } from './tracks.context.types';

import { createContext, useReducer } from 'react';

import tracksReducer from './tracks.context.reducer';
import { TracksActions } from './tracks.context.actions';

const initialState: ContextState = {
  ableToSendFilterTrack: false,
};

export const TracksContext = createContext(initialState as ContextValue);

export const TracksProvider: FC<
  PropsWithChildren<{
    allowFilterOnTracks?: boolean;
  }>
> = ({ allowFilterOnTracks = false, children }) => {
  const [state, dispatch] = useReducer(tracksReducer, initialState);

  const setValueFilterTrack = () => {
    dispatch({
      type: TracksActions.SET_TRACK_FILTER,
      payload: {
        enableFilterTrack: allowFilterOnTracks,
      },
    });
  };

  return (
    <TracksContext.Provider
      value={{
        ...state,
        setValueFilterTrack,
      }}
    >
      {children}
    </TracksContext.Provider>
  );
};
