import type { AdapterInitialConfig } from './adapters.types';

import { PlayerStatus, PlayerViewMode } from '../MeliPlayer.types';

export const defaultState = {
  startOnFullscreen: false,
  status: PlayerStatus.IDLE,
  viewMode: PlayerViewMode.INLINE,
  canHideControls: true,
  config: {
    muted: false,
    autoplay: false,
  },
  isShowingAds: false,
  mutedByAutoplayFallback: false,
  maxBufferedTime: 0,
  muted: false,
  seeking: false,
  stalling: false,
  audioTracks: [],
  subtitleTracks: [],
  subtitleCues: [],
  volume: 100,
  containerNode: { current: null },
} satisfies AdapterInitialConfig;
