import type { FC } from 'react';
import type { TabProps, TabSelectorProps } from './TabSelector.type';

import { Tabs, Tab } from '@andes/tabs';

export const TabSelector: FC<TabSelectorProps> = ({
  accessibilityLabel,
  className,
  classNameTabs,
  selected,
  tabs,
  onClick,
  behavior,
}) => (
  <section className={className}>
    <Tabs
      srLabel={accessibilityLabel}
      className={classNameTabs}
      selectedTab={selected}
      behavior={behavior}
    >
      {tabs.map((tab: TabProps, index: number) => (
        <Tab
          id={`tab-${index}`}
          title={tab.label}
          key={tab.label}
          onClick={() => onClick?.(tab, index)}
        />
      ))}
    </Tabs>
  </section>
);
