export const enum TrackFilter {
  KIDS = 'kids',
  MOVIES = 'movies',
  MUSIC = 'music',
  NEWS = 'news',
  NO_FILTER = 'no_filter',
  SHOWS = 'shows',
  SPORTS = 'sports',
  FILMES = 'movies',
}

const optionsFilter = new Map<string, string>([
  ['deportes', TrackFilter.SPORTS],
  ['infantil', TrackFilter.KIDS],
  ['musica', TrackFilter.MUSIC],
  ['noticias', TrackFilter.NEWS],
  ['peliculas', TrackFilter.MOVIES],
  ['series', TrackFilter.SHOWS],
  ['filmes', TrackFilter.FILMES],
]);

export const getRealFilterTrack = (filter: string | null): string => {
  if (!filter) {
    return TrackFilter.NO_FILTER;
  }

  if (window.MobileWebKit) {
    const hasSubFilters = filter.split('/').length;

    if (hasSubFilters === 2) {
      return (
        optionsFilter.get(filter.split('/')[1].toLocaleLowerCase()) ??
        TrackFilter.NO_FILTER
      );
    }
    return (
      optionsFilter.get(filter.toLocaleLowerCase()) ?? TrackFilter.NO_FILTER
    );
  }

  return (
    optionsFilter.get(filter.split('/')[1].toLocaleLowerCase()) ??
    TrackFilter.NO_FILTER
  );
};
