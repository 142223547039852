import type {
  MeliPlayerComponentsMap,
  MeliPlayerProps,
  MeliPlayerRef,
} from './MeliPlayer.types';
import type { ReactElement, Ref } from 'react';

import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import isEqual from 'lodash/isEqual';

import { PlayerStatus } from './MeliPlayer.types';
import { PlayerContext } from './MeliPlayer.context';
import { useMemoCompare } from './hooks/useMemoCompare';
import { PlayerEvent } from './events.types';
import { usePlayerEvent } from './hooks/usePlayerEvent';
import { adapter as placeholderAdapter } from './adapters/placeholder';
import { defaultState } from './adapters/initialState';
import { MeliPlayerContent } from './MeliPlayerContent';
import { MeliPlayerComponents } from './MeliPlayerComponents.context';
import { ControlsLayer } from './components/layers/ControlsLayer';
import { MeliPlayerTranslationsContext } from './MeliPlayerTranslations.context';
import { ErrorLayer } from './components/layers/ErrorLayer';
import { Overlay } from './components/controls/live/Overlay';
import { SettingsOverlay } from './components/controls/SettingsOverlay';

const defaultComponents: MeliPlayerComponentsMap = {
  ControlsLayer,
  ErrorLayer,
  SettingsOverlay,
  Overlay,
};

export const MeliPlayer = forwardRef(
  (
    {
      adapter,
      className,
      children,
      components = {},
      translations,
      onAll,
      onInit,
      onReady,
      onPlaying,
      onPlaybackFinished,
      onSourceLoading,
      onSourceLoaded,
      onTimeChanged,
      onPaused,
      onSeeked,
      onViewModeChanged,
      onAdBreakStarted,
      onAdBreakFinished,
      onAdPaused,
      onAdResumed,
      onAdSkipped,
      onAdStarted,
      onAdFinished,
      onError,
      startOnFullscreen = false,
      ...props
    },
    ref,
  ) => {
    const [resolvedAdapter, setResolvedAdapter] = useState(() =>
      adapter instanceof Promise ? placeholderAdapter : adapter,
    );
    const adapterConfig = useMemoCompare(props.adapterConfig, isEqual);
    const source = useMemoCompare(props.source, isEqual);
    const config = useMemoCompare(props.config, isEqual);
    const containerRef = useRef<HTMLDivElement>(null);
    const player = useMemo(
      () =>
        resolvedAdapter({
          ...defaultState,
          startOnFullscreen,
          config: {
            ...defaultState.config,
            ...config,
          },
          status: config?.autoplay ? PlayerStatus.LOADING : PlayerStatus.IDLE,
          muted: config?.muted ?? false,
          containerNode: containerRef,
        }),
      [resolvedAdapter],
    );

    const componentsMap = useMemo(
      () => ({
        ...defaultComponents,
        ...components,
      }),
      [components],
    );

    useEffect(() => {
      if (adapter instanceof Promise) {
        adapter
          .then((result) => setResolvedAdapter(() => result))
          .catch(() => {
            /* empty */
          });
      } else if (adapter !== resolvedAdapter) {
        setResolvedAdapter(() => adapter);
      }
    }, [adapter]);

    // It's important to bind events as early as possible
    usePlayerEvent(player, PlayerEvent.ALL, onAll);
    usePlayerEvent(player, PlayerEvent.INIT, onInit);
    usePlayerEvent(player, PlayerEvent.READY, onReady);
    usePlayerEvent(player, PlayerEvent.PLAYING, onPlaying);
    usePlayerEvent(player, PlayerEvent.PLAYBACK_FINISHED, onPlaybackFinished);
    usePlayerEvent(player, PlayerEvent.SOURCE_LOADING, onSourceLoading);
    usePlayerEvent(player, PlayerEvent.SOURCE_LOADED, onSourceLoaded);
    usePlayerEvent(player, PlayerEvent.TIME_CHANGED, onTimeChanged);
    usePlayerEvent(player, PlayerEvent.PAUSED, onPaused);
    usePlayerEvent(player, PlayerEvent.SEEKED, onSeeked);
    usePlayerEvent(player, PlayerEvent.VIEW_MODE_CHANGED, onViewModeChanged);
    usePlayerEvent(player, PlayerEvent.AD_BREAK_STARTED, onAdBreakStarted);
    usePlayerEvent(player, PlayerEvent.AD_BREAK_FINISHED, onAdBreakFinished);
    usePlayerEvent(player, PlayerEvent.AD_STARTED, onAdStarted);
    usePlayerEvent(player, PlayerEvent.AD_FINISHED, onAdFinished);
    usePlayerEvent(player, PlayerEvent.AD_SKIPPED, onAdSkipped);
    usePlayerEvent(player, PlayerEvent.AD_PAUSED, onAdPaused);
    usePlayerEvent(player, PlayerEvent.AD_RESUMED, onAdResumed);
    usePlayerEvent(player, PlayerEvent.ERROR, onError);

    useImperativeHandle(
      ref,
      () => ({
        get: () => player.getState(),
      }),
      [player],
    );

    useEffect(() => {
      const state = player.getState();

      if (containerRef.current) {
        state.init(containerRef.current, adapterConfig);
      }

      return () => state.destroy();
    }, [player, adapterConfig]);

    useEffect(() => {
      if (config) {
        player.getState().setConfig(config);
      }
    }, [player, config]);

    useEffect(() => {
      if (source) {
        player.getState().load(source);
      }
    }, [player, source]);

    return (
      <MeliPlayerTranslationsContext.Provider value={translations}>
        <MeliPlayerComponents.Provider value={componentsMap}>
          <PlayerContext.Provider value={player}>
            <MeliPlayerContent className={className} ref={containerRef}>
              {children}
            </MeliPlayerContent>
          </PlayerContext.Provider>
        </MeliPlayerComponents.Provider>
      </MeliPlayerTranslationsContext.Provider>
    );
  },
) as <T>(
  props: MeliPlayerProps<T> & {
    ref?: Ref<MeliPlayerRef<T>>;
  },
) => ReactElement;

(MeliPlayer as React.FC).displayName = 'MeliPlayer';
