import type { CarouselFreeProps, CarouselSnappedProps } from './Carousel.type';

import { CarouselFreeSlide, CarouselFree } from '@andes/carousel-free';
import { CarouselSnappedSlide, CarouselSnapped } from '@andes/carousel-snapped';
import classnames from 'classnames';

import { ComponentMapper } from '../ComponentMapper';
import { MaskCarousel } from '../Svg/desktop';

export const Carousel: React.FC<CarouselFreeProps | CarouselSnappedProps> = ({
  components,
  knownComponents,
  spacing,
  accessibilityLabel = 'carousel',
  mode = 'free',
  carouselType,
  className,
  ...props
}) => {
  const componentsFiltered = components.filter(
    (component) => Object.keys(component.props.header.default).length > 0,
  );

  const Carousels = {
    free: {
      mode: CarouselFree,
      slide: CarouselFreeSlide,
    },
    snapped: {
      mode: CarouselSnapped,
      slide: CarouselSnappedSlide,
    },
  };

  const CarouselMode = Carousels[mode].mode;
  const CarouselSlide = Carousels[mode].slide;

  return (
    <CarouselMode
      className={classnames(`carousel-${mode}`, className)}
      srLabel={accessibilityLabel}
      {...props}
    >
      {componentsFiltered.map((component, index) => {
        const key = `${component.type}-${index}`;

        return (
          <CarouselSlide key={key}>
            <ComponentMapper
              key={key}
              components={knownComponents!}
              {...component}
            />
            {carouselType === 'ranking' && (
              <>
                <MaskCarousel />
                <div className="carousel__item-rank">{index + 1}</div>
              </>
            )}
          </CarouselSlide>
        );
      })}
    </CarouselMode>
  );
};
