import type {
  AdsType,
  DrmConfigProps,
  PlaybackType,
  SourceType,
  SubtitleType,
  ThumbnailProps,
} from '../../../../container/MeliPlayerContainer';
import type { Tracks } from '../../../../utils/track';
import type { KnownComponent } from '../../../../../types/components';

export type PlayBackContext = {
  playback?: PlaybackType;
  ads?: AdsType;
  sources: Partial<Record<SourceType, string>>;
  subtitles?: Array<SubtitleType>;
  thumbnails?: ThumbnailProps;
  drm?: DrmConfigProps;
};

export enum MimeTypes {
  'MP4' = 'video/mp4',
  'WEBM' = 'video/webm',
}

export type MediaSource = {
  url?: string;
  type: MimeTypes;
};

export type DrmConfigMediaCardPlayer = {
  dash?: string;
  progressive?: Array<MediaSource>;
  hls?: string;
};

export type MediaCardVideoPlayerProps = {
  className?: string;
  playbackContext: PlayBackContext;
  onReady?: () => void;
  onPlaying?: () => void;
  onPlaybackFinished?: () => void;
  playing?: boolean;
  playVideo?: boolean;
  tracks?: Tracks;
  ui?: {
    finishedOverlay: {
      buttonLabel: string;
    };
    controls?: Array<KnownComponent>;
  };
};
