export enum SkeletonVariants {
  CIRCLE = 'circle',
  RECTANGLE = 'rectangle',
}

export enum SkeletonColors {
  DARK = 'dark',
  LIGHT = 'light',
  DARKER = 'darker',
  LIGHTER = 'lighter',
}

export type SkeletonProps = {
  variant?: SkeletonVariants;
  color?: SkeletonColors;
  animation?: boolean;
  shadow?: boolean;
  className?: string;
  width?: string;
  roundedCorner?: 'm' | 's';
};
