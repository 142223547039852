import type { FC, SVGProps } from 'react';

export const PlayIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 21V3l15 9-15 9Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);
