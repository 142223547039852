import type { FC } from 'react';
import type { BackButtonProps } from './BackButton.type';

import { Button as BaseButton } from '../../../Button/mobile';

export const BackButton: FC<BackButtonProps> = ({ data, className }) => {
  if (!data) {
    return null;
  }

  return (
    <BaseButton
      hierarchy="transparent"
      size="medium"
      className={className}
      {...data}
    />
  );
};
