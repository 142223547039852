import type { MutableRefObject } from 'react';

import { useEffect, useRef, useState } from 'react';

import { ScrollDirection } from '../StickyScrollContainer.types';

export const useScrollDirListener = (
  stickyContainerRef: MutableRefObject<HTMLElement>,
) => {
  const [scrollDir, setScrollDir] = useState(ScrollDirection.NO);
  const stickyTopInitial = useRef(false);
  const stickyTop = useRef(0);
  const lastScrollY = useRef(0);

  useEffect(() => {
    const stickyRef = stickyContainerRef.current;
    const setInitialTop = () => {
      if (!stickyTopInitial.current) {
        stickyTop.current = stickyRef.offsetTop;
        stickyTopInitial.current = true;
      }
    };
    const noScrollClasses = (scrollY: number) => {
      const headerMeasure = stickyRef.clientHeight + stickyTop.current;
      const validateNoUp = scrollDir !== ScrollDirection.UP;

      if (scrollY < headerMeasure && validateNoUp) {
        setScrollDir(ScrollDirection.NO);

        return true;
      }

      if (scrollY < stickyTop.current) {
        setScrollDir(ScrollDirection.NO);

        return true;
      }

      return false;
    };
    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      const noScroll = noScrollClasses(scrollY);

      if (noScroll) {
        return;
      }

      setScrollDir(
        scrollY > lastScrollY.current
          ? ScrollDirection.DOWN
          : ScrollDirection.UP,
      );
      lastScrollY.current = scrollY;
    };

    const onScroll = () => {
      window.requestAnimationFrame(updateScrollDir);
    };

    setInitialTop();
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir]);

  return { scrollDir };
};
