import type { FC, SVGProps } from 'react';

export const CloseIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillOpacity={0.9}
      d="m4.94 6 6.003 6.003-6.004 6.004L6 19.067l6.003-6.003L18 19.061 19.06 18l-5.996-5.997 5.997-5.996L18 4.947l-5.997 5.996L6 4.939 4.94 6Z"
    />
  </svg>
);
