import type React from 'react';
import type { TypographyApiProps } from '../../../Typography/mobile';
import type { FeedWrapperProps } from '../../Feed.types';

import { Typography } from '../../../Typography/mobile';

export const TypographyWrapper: React.FC<
  FeedWrapperProps<TypographyApiProps>
> = ({ indexInFeed, ...props }) => (
  <Typography type="title" size="s" {...props} />
);
