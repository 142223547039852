import { useCallback, useEffect, useRef } from 'react';

import { usePlayer } from '../MeliPlayer.context';
import { PlayerStatus } from '../MeliPlayer.types';
import { PlayerEvent } from '../events.types';

/**
 * We have to set `seeking` to true when the user starts dragging, until
 * the drag is released and the player has resumed playback, AFTER buffering.
 */
export const useSmoothDragSeek = () => {
  const seeking = usePlayer(({ seeking: playerSeeking }) => playerSeeking);
  const getStatus = usePlayer(
    ({ getStatus: playerGetStatus }) => playerGetStatus,
  );
  const setSeeking = usePlayer(
    ({ setSeeking: playerSetSeeking }) => playerSetSeeking,
  );
  const play = usePlayer(({ play: playerPlay }) => playerPlay);
  const pause = usePlayer(({ pause: playerPause }) => playerPause);
  const on = usePlayer(({ on: playerOn }) => playerOn);

  /**
   * If the player was playing before dragging, we should pause while
   * the drag is ongoing, but restore playback afterwards.
   */
  const statusBeforeDragging = useRef<PlayerStatus | null>();

  useEffect(() => {
    if (!seeking) {
      return () => undefined;
    }

    const dispose = on(PlayerEvent.PLAYING, () => {
      statusBeforeDragging.current = null;
      setSeeking(false);
    });

    return () => dispose();
  }, [seeking]);

  const onDragStart = useCallback(() => {
    const currentStatus = getStatus();

    setSeeking(true);
    statusBeforeDragging.current = currentStatus;

    if (currentStatus === PlayerStatus.PLAYING) {
      pause();
    }
  }, []);

  const onDragEnd = useCallback(() => {
    if (statusBeforeDragging.current === PlayerStatus.PLAYING) {
      play();
    }
  }, []);

  return {
    onDragStart,
    onDragEnd,
  };
};
