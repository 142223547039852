import type { FC, TransitionEvent } from 'react';
import type { LayerProps } from '../ui/Layer';

import { useEffect, useState } from 'react';

import classNames from 'classnames';

import { SoundOffIcon } from '../icons/SoundOffIcon';
import { ButtonText } from '../ui/Button';
import { Layer } from '../ui/Layer';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';

export const AutoplayUnmuteButton: FC<LayerProps> = ({
  className,
  visible,
  onTransitionEnd,
  ...props
}) => {
  const [hasBeenVisible, setHasBeenVisible] = useState(false);
  const { volumeAutoplayUnmuteLabel } = useMeliPlayerTranslations();

  useEffect(() => {
    if (visible && !hasBeenVisible) {
      setHasBeenVisible(true);
    }
  }, [visible, hasBeenVisible]);

  const handleTransitionEnd = (event: TransitionEvent<HTMLDivElement>) => {
    // When the icon moves left
    if (event.propertyName === 'left') {
      onTransitionEnd?.(event);
    }
  };

  return (
    <Layer
      {...props}
      data-testid="autoplay-fallback"
      visible={visible}
      className={classNames('meli-player__autoplay-unmute-layer', className, {
        'meli-player__autoplay-unmute-layer--animated': hasBeenVisible,
      })}
      onTransitionEnd={handleTransitionEnd}
      bottomShadow
    >
      <div className="meli-player__button meli-player__autoplay-unmute-button">
        <SoundOffIcon /> <ButtonText>{volumeAutoplayUnmuteLabel}</ButtonText>
      </div>
    </Layer>
  );
};
