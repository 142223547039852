import type { GetFeedProps, GetFeedResult } from './feedContainer.types';
import type { ContentIdType } from '../../../../services/middleend-page-service';
import type {
  OptionList,
  UpdateKeepWatching,
} from '../components/KeepWatching/hook/useKeepWatching/useKeepWatching.type';

import axios from 'nordic-axios';

import { CustomError } from '../../../../services/utils/custom-error';
import { restClient } from '../../../common/rest-client';
import api from '../../../constants/api.json';

const { PATH_API } = api;

export const getFeed = async ({
  filter,
  config,
  limit,
  offset,
  context,
  isWebview,
}: GetFeedProps): Promise<GetFeedResult> => {
  try {
    const { data } = await restClient.get<GetFeedResult>(PATH_API.FEED, {
      ...config,
      headers: {
        ...(config?.headers as Record<string, string>),
        ...context?.headers,
      },
      params: {
        filter,
        limit,
        offset,
        ...(context?.params || {}),
      },
      timeout: isWebview ? 10000 : undefined,
    });

    return data;
  } catch (error: unknown) {
    throw axios.isCancel(error) ? error : new CustomError(error);
  }
};

export async function getOptionsKeepWatching(
  contentId: ContentIdType,
  context: {
    params?: Record<string, string>;
    headers?: Record<string, string>;
  },
) {
  return restClient
    .get<OptionList>(PATH_API.KEEP_WATCHING, {
      params: { contentId, ...(context?.params || {}) },
      headers: {
        ...context?.headers,
      },
    })
    .then(({ data }) => data)
    .catch((error: unknown) => {
      throw axios.isCancel(error) ? error : new CustomError(error);
    });
}

export async function removeFromKeepWatching(
  contentId: ContentIdType,
  context: {
    params?: Record<string, string>;
    headers?: Record<string, string>;
  },
) {
  return restClient
    .delete<UpdateKeepWatching>(PATH_API.KEEP_WATCHING, {
      params: { contentId, ...(context?.params || {}) },
      headers: {
        ...context?.headers,
      },
    })
    .then(({ data }) => data)
    .catch((error: unknown) => {
      throw axios.isCancel(error) ? error : new CustomError(error);
    });
}

export async function currentKeepWatching(context: {
  params?: Record<string, string>;
  headers?: Record<string, string>;
}) {
  return restClient
    .get<UpdateKeepWatching>(PATH_API.KEEP_WATCHING, {
      params: { ...(context?.params || {}) },
      headers: {
        ...context?.headers,
      },
      timeout: 10000,
    })
    .then(({ data }) => data)
    .catch((error: unknown) => {
      throw axios.isCancel(error) ? error : new CustomError(error);
    });
}

export async function getCarousels(
  carouselsIds: string[],
  context: {
    params?: Record<string, string>;
    headers?: Record<string, string>;
  },
) {
  return restClient
    .get<UpdateKeepWatching>(PATH_API.CAROUSELS, {
      headers: {
        ...context?.headers,
      },
      params: {
        ...(context?.params || {}),
        ids: carouselsIds.join(','),
      },
      timeout: 10000,
    })
    .then(({ data }) => data)
    .catch((error: unknown) => {
      throw axios.isCancel(error) ? error : new CustomError(error);
    });
}
