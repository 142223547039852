import type { FC } from 'react';
import type {
  TagSelectorProps,
  TagsProps,
} from '../../../../components/TagSelector';

import { TagSelector as TagSelectorComponent } from '../../../../components/TagSelector';

type TagSelectorL2Props = {
  tagSelector?: TagSelectorProps;
  handleChangeSubfilter?: (tag: TagsProps, index: number) => void;
};

export const TagSelector: FC<TagSelectorL2Props> = ({
  tagSelector,
  handleChangeSubfilter,
}) => {
  if (!tagSelector) {
    return null;
  }

  return (
    <TagSelectorComponent
      className="hub-filter__tag-selector"
      handleChangeSubfilter={handleChangeSubfilter}
      {...tagSelector}
    />
  );
};
