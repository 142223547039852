import type { FC, SVGProps } from 'react';

export const SettingsOnIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width={21}
      height={16.5}
      x={1.5}
      y={4}
      fill="currentColor"
      fillOpacity={0.9}
      rx={3}
    />
    <path
      stroke="#000"
      strokeWidth={1.5}
      d="M5.25 12.25h8.25m3 0H18m-12.75 3H18"
    />
  </svg>
);
