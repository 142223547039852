import type { ReactNode } from 'react';

import { forwardRef } from 'react';

import classNames from 'classnames';

import { usePlayer } from './MeliPlayer.context';
import { LayerManager } from './components/controls';

export type MeliPlayerContentProps = {
  className?: string;
  children?: ReactNode;
};

export const MeliPlayerContent = forwardRef<
  HTMLDivElement,
  MeliPlayerContentProps
>(({ className, children }, ref) => {
  const viewMode = usePlayer(({ viewMode }) => viewMode);
  const startOnFullscreen = usePlayer(
    ({ startOnFullscreen }) => startOnFullscreen,
  );

  return (
    <div
      className={classNames(
        'meli-player',
        `meli-player--${viewMode.toLowerCase()}`,
        { 'meli-player--fullscreen-force': startOnFullscreen },
        className,
      )}
      ref={ref}
    >
      {/* workaround for bitmovin style overrides */}
      {children ?? <LayerManager />}
    </div>
  );
});
