import type { FC } from 'react';

import { Modal } from '@andes/modal';
import { useUncontrolled } from 'uncontrollable';
import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';

import {
  TypeModal,
  type DialogBoxContractProps,
  type OptionsDialogBoxContract,
} from './DialogBox.type';
import { OptionList } from './components/OptionsList/OptionsList';

export const DialogBox: FC<DialogBoxContractProps> = (props) => {
  const {
    title,
    onVisibleChange,
    visible = false,
    loading = false,
    optionsList,
    handleSelectOption = () => null,
    closable,
    type = TypeModal.SMALL,
  } = useUncontrolled(props, {
    visible: 'onVisibleChange',
  });

  return (
    <Modal
      open={visible}
      onClose={() => onVisibleChange?.(false)}
      title={title}
      className="dialog-box"
      closeButtonLabel="Cerrar"
      closable={closable}
      type={type}
      srLabel="Cerrar"
    >
      {loading && (
        <div className="dialog-box-overlay__loading-container">
          <ProgressIndicatorCircular size="medium" />
        </div>
      )}

      {!loading && optionsList && (
        <div className="dialog-box-overlay__container">
          {optionsList.props.options.map(
            (props: OptionsDialogBoxContract, index) => {
              const {
                props: { action },
              } = props;
              const key = `${action.type}-${index}`;

              return (
                <OptionList
                  key={key}
                  {...props}
                  onClickSelectOption={() => handleSelectOption(action)}
                />
              );
            },
          )}
        </div>
      )}
    </Modal>
  );
};
