import type { FC } from 'react';
import type { SnackbarProps } from './Snackbar.types';

import { Snackbar as SnackbarAndes } from '@andes/snackbar';

import alerts from '../../constants/alerts.json';
import { SnackbarMapColor } from './Snackbar.types';

const { SNACKBAR } = alerts;

export const SnackbarDuration = new Map(Object.entries(SNACKBAR));
export const Snackbar: FC<SnackbarProps> = ({
  duration = 'normal',
  show = false,
  text,
  type = 'success',
  classNames,
  action,
  actionPosition,
  onActionClick,
}) => (
  <SnackbarAndes
    delay={SnackbarDuration.get(duration)}
    message={text}
    show={show}
    color={SnackbarMapColor.get(type)}
    className={classNames}
    action={
      action && {
        text: action.props.label,
        position: actionPosition,
        onClick: onActionClick,
      }
    }
  />
);
