import type { FC, SVGProps } from 'react';

export const PauseIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.75 3.75h1.5v16.5h-1.5zM15.75 3.75h1.5v16.5h-1.5z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);
