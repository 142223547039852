import { useContext } from 'react';

import { LoadingOverlay as BaseLoadingOverlay } from '../../../../../components/LoadingOverlay';
import { HubContext } from '../../../context';
import { i18nContext } from '../../../../../context';

export const LoadingOverlay = () => {
  const { overlayLoading } = useContext(HubContext);
  const { get } = useContext(i18nContext);

  if (!overlayLoading.show) {
    return null;
  }

  const {
    components: { loadingLabel, spinner },
  } = overlayLoading;

  return (
    <BaseLoadingOverlay
      label={get(loadingLabel?.props.label || 'cargando')}
      {...loadingLabel?.props}
      {...spinner?.props}
    />
  );
};
