import type { FC, SVGProps } from 'react';

export const CheckMarkIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillOpacity={0.9}
      d="m10.182 14.094 6.865-6.865L18.59 8.77l-8.408 8.408-4.772-4.771 1.543-1.543 3.229 3.229Z"
    />
  </svg>
);
