import type { DependencyList } from 'react';

import { useEffect, useRef } from 'react';

import { type PlayerListeners } from '../../../components/Player/Player.types';

type MeasuresTimestamps = {
  initStart: number;
  initEnd: number;
  metadataStart: number;
  metadataEnd: number;
  prebufferStart: number;
  prebufferEnd: number;
  adsStart: number;
  adsEnd: number;
};

type PerformanceData = {
  playerInitDuration: number;
  metadataLoadDuration: number;
  adsDuration: number;
  prebufferDuration: number;
  totalDuration: number;
  source: string;
};

const getPerformanceData = (
  measures: MeasuresTimestamps,
  source: string,
): PerformanceData => {
  const adsDuration = measures.adsEnd - measures.adsStart;

  return {
    playerInitDuration: measures.initEnd - measures.initStart,
    metadataLoadDuration: measures.metadataEnd - measures.metadataStart,
    prebufferDuration:
      measures.prebufferEnd - measures.prebufferStart - adsDuration,
    totalDuration: measures.prebufferEnd - measures.initStart,
    adsDuration,
    source,
  };
};

export const usePerformanceEvents = (
  callback: (data: PerformanceData) => void,
  dependencies: DependencyList,
): Partial<PlayerListeners> => {
  const isMeasuring = useRef(false);
  const isShowingAd = useRef(false);
  const notifyAfterAd = useRef(false);
  const measures = useRef<MeasuresTimestamps>({
    initStart: 0,
    initEnd: 0,
    metadataStart: 0,
    metadataEnd: 0,
    prebufferStart: 0,
    prebufferEnd: 0,
    adsStart: 0,
    adsEnd: 0,
  });

  useEffect(() => {
    measures.current.initStart = Date.now();

    // Wil be written if the player actually initializes in
    // this render (could be cached)
    measures.current.initEnd = Date.now();
  }, dependencies);

  const notify = (streamType: string) => {
    if (!isMeasuring.current) {
      return;
    }

    callback(getPerformanceData(measures.current, streamType));
    isMeasuring.current = false;
  };

  return {
    onInit: (event) => {
      measures.current.initEnd = event.timestamp;
    },
    onAdBreakStarted: (event) => {
      isShowingAd.current = true;
      measures.current.adsStart = event.timestamp;
    },
    onAdBreakFinished: (event) => {
      measures.current.adsEnd = event.timestamp;
      isShowingAd.current = false;

      if (notifyAfterAd.current) {
        notify(event.player.get().getStreamType());
      }
    },
    onSourceLoading: (event) => {
      // Reset flags because the source changed
      isMeasuring.current = true;
      notifyAfterAd.current = false;
      isShowingAd.current = false;

      measures.current.metadataStart = event.timestamp;

      // Reset ads measures in case this new souce doesn't have prerolls
      measures.current.adsStart = 0;
      measures.current.adsEnd = 0;
    },
    onSourceLoaded: (event) => {
      measures.current.metadataEnd = event.timestamp;
      measures.current.prebufferStart = event.timestamp;
    },
    onReady: (event) => {
      measures.current.prebufferEnd = event.timestamp;

      if (!isMeasuring.current) {
        return;
      }

      // Ready is fired before AdBreakFinished during mid-rolls that behave
      // like pre-rolls.
      if (isShowingAd.current) {
        notifyAfterAd.current = true;
      } else {
        notify(event.player.get().getStreamType());
      }
    },
  };
};
