import type { CarouselFreeProps } from '../../../../../components/Carousel';

import { TypeKnownComponent } from '../../../../../../types/components';
import { Carousel } from '../../../../../components/Carousel';
import { FullMediaCard } from '../../../../../components/FullMediaCard/desktop';

const knownComponents: CarouselFreeProps['knownComponents'] = {
  [TypeKnownComponent.FULL_MEDIA_CARD]: (props) => <FullMediaCard {...props} />,
};

export const MainSlider = ({
  components,
  accessibilityLabel,
  slidesPerView,
  ...props
}: CarouselFreeProps) => {
  const filteredComponents = components.filter(
    (component) => component.props.header.default.background !== undefined,
  );
  return (
    <article className="feed__item-full">
      <Carousel
        lazyload
        pagination
        mode="snapped"
        slidesPerView={1}
        arrows={{ size: 'small' }}
        spacing={0}
        components={filteredComponents}
        knownComponents={knownComponents}
        accessibilityLabel={accessibilityLabel}
        {...props}
      />
    </article>
  );
};
