import type { FC } from 'react';

import { usePlayer } from '../../MeliPlayer.context';
import { Slider } from '../ui/Slider';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';

export type VolumeBarProps = {
  onDraggingChange: (isDragging: boolean) => void;
};

export const VolumeBar: FC<VolumeBarProps> = ({ onDraggingChange }) => {
  const { volumeAriaLabel } = useMeliPlayerTranslations();
  const setVolume = usePlayer(
    ({ setVolume: playerSetVolume }) => playerSetVolume,
  );
  const volume = usePlayer(({ volume: playerVolume }) => playerVolume);

  return (
    <Slider
      className="meli-player__progress-bar"
      minValue={0}
      maxValue={100}
      value={volume}
      onChange={(value) => {
        setVolume(Array.isArray(value) ? value[0] : value);
      }}
      label={volumeAriaLabel || ''}
      onDragStart={() => onDraggingChange(true)}
      onDragEnd={() => onDraggingChange(false)}
    />
  );
};
