import type { FC } from 'react';
import type { MediaCardVerticalProps } from '../MediaCardVertical.types';

import Card from '@andes/card';
import classname from 'classnames';

import { Image } from '../../Image/mobile';
import { Link } from '../../Link/Link';

const namespace = 'mediacard-vertical';

export const MediaCardVertical: FC<MediaCardVerticalProps> = ({
  size,
  header,
  linkTo,
  carouselType = 'vertical',
}) =>
  header.default.background ? (
    <Card
      className={classname(namespace, `size--${size}`, `type--${carouselType}`)}
      shadow="elevated"
    >
      <Link to={linkTo} className="poster-media-card__link">
        <Image
          carousel
          preload
          lazy="off"
          {...header.default.background.props}
          className="poster-media-card__image"
        />
      </Link>
    </Card>
  ) : null;
