import type { MediaCardWrapperProps } from './MediaCardWrapper.types';

import { type FC } from 'react';

import { MediaCardFull } from '../../../MediaCard/components/MediaCardFull';
import { useVideoPreview } from './hooks/useVideoPreview';

const MediaCard: FC<MediaCardWrapperProps> = (props) => {
  const { indexInFeed, header, ...otherProps } = props;
  const previewProps = useVideoPreview({
    indexInFeed,
    hasPreview: !!header.active,
  });

  return (
    <MediaCardFull
      {...otherProps}
      {...previewProps}
      header={header}
      className="mediacard-wrapper"
    />
  );
};

export const MediaCardWrapper: FC<MediaCardWrapperProps> = (props) => (
  <MediaCard {...props} />
);
