import type { FC } from 'react';
import type { TagSelectorProps, TagsProps } from './TagSelector.types';

import { useEffect, useState, useRef } from 'react';

import { Tabs, Tab } from '@andes/tabs';
import classNames from 'classnames';

import { useTagColor } from './hook/useTagColor';

export const TagSelector: FC<TagSelectorProps> = ({
  props,
  className,
  handleChangeSubfilter,
}) => {
  const currentRef = useRef<HTMLDivElement | null>(null);
  const { tags = [], selected, ...otherProps } = props;
  const [selectedTag, setSelectedTag] = useState(selected);

  const handlerTagSelected = (tagSelected: number, tag: TagsProps) => {
    setSelectedTag(tagSelected);
    handleChangeSubfilter?.(tag, tagSelected);

    if (!window.MobileWebKit) {
      window.scrollTo(0, 0);
    }
  };

  const validateSelectedSubFilter = (): number => {
    const isMobileWebKit = window.MobileWebKit;
    const currentUrlClean = isMobileWebKit
      ? new URLSearchParams(window.location.search).get('filters')
      : window.location.pathname.replace(/^\//, '');

    return tags.findIndex((tag) => tag.filter === currentUrlClean) || -1;
  };

  useEffect(() => {
    const wrapperScrollEl = currentRef?.current?.querySelector(
      '.andes-tabs__wrapper--scrollable',
    );

    if (wrapperScrollEl) {
      wrapperScrollEl.scrollTo({ left: 0, behavior: 'instant' });
    }

    setSelectedTag(
      validateSelectedSubFilter() !== -1 ? validateSelectedSubFilter() : 0,
    );
  }, [props]);

  useEffect(() => {
    const firstTab = tags[0];

    if (validateSelectedSubFilter() === -1) {
      handlerTagSelected(0, firstTab);
    }
  }, []);

  useTagColor({
    ...otherProps,
  });

  return (
    <section className={classNames('tag-selector', className)}>
      <Tabs
        selectedTab={selectedTag}
        className="tag-selector__tabs"
        ref={currentRef}
      >
        {tags.map((tag: TagsProps, index: number) => (
          <Tab
            id={`tab-${index}`}
            title={tag.label}
            key={tag.label}
            className={classNames('tag-selector__tag', {
              'tag-selector__tag--active': selectedTag === index,
            })}
            onClick={() => handlerTagSelected(index, tag)}
          />
        ))}
      </Tabs>
    </section>
  );
};
