import type { CarouselFreeProps } from '../../../../../components/Carousel';

import { useMemo } from 'react';

import { TypeKnownComponent } from '../../../../../../types/components';
import { Carousel } from '../../../../../components/Carousel';
import { MediaCardVertical } from '../../../../../components/MediaCardVertical/desktop/MediaCardVertical';
import { MediaCardWrapper } from '../../../../../components/Feed/wrappers/MediaCardWrapper';

const SLIDES_LIVE_CAROUSEL = 4;

export const Vertical: React.FC<CarouselFreeProps> = ({
  components,
  spacing,
  accessibilityLabel,
  mode = 'free',
  carouselType = 'vertical',
  className,
  slidesPerView,
  ...props
}) => {
  const memoknownComponents = useMemo(() => {
    const knownComponents: CarouselFreeProps['knownComponents'] = {
      [TypeKnownComponent.VERTICAL_MEDIA_CARD]: (props) => (
        <MediaCardVertical {...props} carouselType={carouselType} />
      ),
      [TypeKnownComponent.MEDIA_CARD]: (props) => (
        <MediaCardWrapper {...props} redirectAnchor />
      ),
    };

    return knownComponents;
  }, []);

  return (
    <article className={className || ''}>
      <Carousel
        lazyload
        slidesPerView={
          carouselType === 'live' ? SLIDES_LIVE_CAROUSEL : slidesPerView
        }
        components={components}
        spacing={spacing}
        accessibilityLabel={accessibilityLabel}
        mode={mode}
        knownComponents={memoknownComponents}
        {...props}
      />
    </article>
  );
};
