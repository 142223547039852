import { usePlayer } from '../../MeliPlayer.context';
import { formatTime } from '../../utils/formatTime';

export const ProgressLabel = () => {
  const { currentTime, totalDuration } = usePlayer(
    ({
      currentTime: playerCurrentTime,
      totalDuration: playerTotalDuration,
    }) => ({
      currentTime: formatTime(playerCurrentTime ?? 0),
      totalDuration: formatTime(playerTotalDuration ?? 0),
    }),
  );

  return (
    <div className="meli-player__progress-label">
      <span className="meli-player__progress-label--primary">
        {currentTime}
      </span>{' '}
      <span className="meli-player__progress-label--secondary">
        / {totalDuration}
      </span>
    </div>
  );
};
