import type { ReactNode } from 'react';
import type { StickyContextType } from './StickyHeaderProvider.types';

import { createContext, useContext, useState } from 'react';

export const StickyContext = createContext<StickyContextType>({
  isFirstTab: true,
  hasSubfilters: false,
  handleSubfilters: () => {},
  handleFirstTab: () => {},
});

export const StickyHeaderProvider = ({ children }: { children: ReactNode }) => {
  const [isFirstTab, setIsFirstTab] = useState<boolean>(true);
  const [hasSubfilters, setHasSubfilters] = useState<boolean>(false);
  const handleSubfilters = (value: boolean) => {
    setHasSubfilters(value);
  };
  const handleFirstTab = (value: boolean) => {
    setIsFirstTab(value);
  };

  return (
    <StickyContext.Provider
      value={{ isFirstTab, hasSubfilters, handleSubfilters, handleFirstTab }}
    >
      {children}
    </StickyContext.Provider>
  );
};

export const useStickyHeader = () => useContext(StickyContext);
