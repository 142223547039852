import { useLayoutEffect, useRef, useState } from 'react';

import { BreakpointsSlides } from '../CarouseSnapped.types';

const SlidesPerView = {
  DEFAULT: 3,
  [BreakpointsSlides.XS]: 4,
  [BreakpointsSlides.SM]: 5,
  [BreakpointsSlides.MD]: 6,
  [BreakpointsSlides.LG]: 7,
  [BreakpointsSlides.XL]: 8,
};

export const useSlidesPerView = () => {
  const [slides, setSlides] = useState(SlidesPerView[BreakpointsSlides.MD]);
  const disposeListenersRef = useRef<Array<() => void>>([]);

  useLayoutEffect(() => {
    const mediaQueryList: Record<BreakpointsSlides, MediaQueryList> = {
      [BreakpointsSlides.DEFAULT]: window.matchMedia(
        `(max-width: ${BreakpointsSlides.XS})`,
      ),
      [BreakpointsSlides.XS]: window.matchMedia(
        `(min-width: ${BreakpointsSlides.XS}) and (max-width: ${BreakpointsSlides.SM})`,
      ),
      [BreakpointsSlides.SM]: window.matchMedia(
        `(min-width: ${BreakpointsSlides.SM}) and (max-width: ${BreakpointsSlides.MD})`,
      ),
      [BreakpointsSlides.MD]: window.matchMedia(
        `(min-width: ${BreakpointsSlides.MD}) and (max-width: ${BreakpointsSlides.LG})`,
      ),
      [BreakpointsSlides.LG]: window.matchMedia(
        `(min-width: ${BreakpointsSlides.LG}) and (max-width: ${BreakpointsSlides.XL})`,
      ),
      [BreakpointsSlides.XL]: window.matchMedia(
        `(min-width: ${BreakpointsSlides.XL})`,
      ),
    };
    const changeSlidesPerView = (breakpoint: BreakpointsSlides) => {
      if (mediaQueryList[breakpoint].matches) {
        setSlides(SlidesPerView[breakpoint]);
      }
    };

    const breakpoints = Object.keys(mediaQueryList) as Array<BreakpointsSlides>;

    breakpoints.forEach((key: BreakpointsSlides) => {
      changeSlidesPerView(key);

      const callbackListener = () => changeSlidesPerView(key);

      mediaQueryList[key].addEventListener('change', callbackListener);
      disposeListenersRef.current.push(() =>
        mediaQueryList[key].removeEventListener('change', callbackListener),
      );
    });

    return () => disposeListenersRef.current.forEach((listener) => listener());
  }, []);

  return {
    slides,
  };
};
