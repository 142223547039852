import type { FC } from 'react';

import classNames from 'classnames';
import { Image } from 'nordic/image';

import { Layer, type LayerProps } from '../ui';
import { usePlayer } from '../../MeliPlayer.context';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';

export const PosterLayer: FC<LayerProps> = ({ className, ...props }) => {
  const poster = usePlayer(({ config }) => config.poster);
  const { posterAltLabel } = useMeliPlayerTranslations();

  if (!poster) {
    return null;
  }

  return (
    <Layer
      data-testid="poster-layer"
      className={classNames('meli-player__poster-layer', className)}
      {...props}
    >
      {}
      <Image src={poster} alt={posterAltLabel!} />
    </Layer>
  );
};
