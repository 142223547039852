import { useEffect, useState } from 'react';

import { usePlayer } from '../../MeliPlayer.context';
import { PlayerStatus } from '../../MeliPlayer.types';
import { PauseIcon } from '../icons/PauseIcon';
import { PlayIcon } from '../icons/PlayIcon';
import { Button } from '../ui/Button';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';
import { IssuerEventType } from '../../events.types';

export const PlaybackButton = () => {
  const { pauseAriaLabel, playAriaLabel } = useMeliPlayerTranslations();
  const status = usePlayer(({ status: playerStatus }) => playerStatus);
  const seeking = usePlayer(({ seeking: playerSeeking }) => playerSeeking);
  const play = usePlayer(({ play: playerPlay }) => playerPlay);
  const pause = usePlayer(({ pause: playerPause }) => playerPause);
  const [isPlaying, setIsPlaying] = useState(status === PlayerStatus.PLAYING);
  const Icon = isPlaying ? PauseIcon : PlayIcon;
  const label = isPlaying ? pauseAriaLabel : playAriaLabel;

  useEffect(() => {
    // Playback button shouldn't update while seeking
    if (seeking) {
      return;
    }

    setIsPlaying(status === PlayerStatus.PLAYING);
  }, [seeking, status]);

  const handleClick = () => {
    if (isPlaying) {
      pause(IssuerEventType.MELIPLAYER);
    } else {
      play(IssuerEventType.MELIPLAYER);
    }
  };

  return (
    <Button aria-label={label} onClick={handleClick}>
      <Icon />
    </Button>
  );
};
