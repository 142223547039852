import type { ContextState } from '../context/HubState.types';
import type { Action } from './hub.types';

import { HubActions } from './hub.actionsTypes';
import { KnownComponent } from '../../../../types/components';

type CarouselComponent = Extract<KnownComponent, { type: 'carousel' }>;

export default function hubReducer(
  state: ContextState,
  action: Action,
): ContextState {
  switch (action.type) {
    case HubActions.NEXT_PAGE_AND_DATA:
      return {
        ...state,
        nextPage: action.payload.nextPage,
        data: [...(state.data || []), ...action.payload.data],
      };

    case HubActions.SET_FILTER:
      return {
        ...state,
        data: [],
        filter: action.payload.filter,
        previousFilter: state.filter,
        nextPage: action.payload.nextPage,
      };

    case HubActions.SET_INITIAL_FILTER:
      return {
        ...state,
        data: [],
        filter: action.payload.filter,
        previousFilter: action.payload.filter,
        nextPage: action.payload.nextPage,
      };

    case HubActions.SET_INITIAL_DATA:
      return {
        ...state,
        data: Array.from(action.payload.data),
        nextPage: action.payload.nextPage,
      };

    case HubActions.ADD_NEW_SNACKBAR:
      return {
        ...state,
        snackbars: [...state.snackbars, action.payload.snackbar],
      };

    case HubActions.SHOW_OVERLAY_LOADING:
      return {
        ...state,
        overlayLoading: {
          ...state.overlayLoading,
          show: action.payload.visible,
        },
      };

    case HubActions.SET_COMPONENTS_OVERLAY_LOADING:
      return {
        ...state,
        overlayLoading: {
          ...state.overlayLoading,
          components: action.payload.components,
        },
      };

    case HubActions.CHANGE_MUTED_VALUE:
      return {
        ...state,
        isMuted: !state.isMuted,
      };

    case HubActions.UPDATE_CAROUSELS_COMPONENTS:
      return {
        ...state,
        data: state.data?.map((feedComponent, index) => {
          const componentToReplace = action.payload.data.find((component) => {
            const { props } = component;
            const feedComponentProps = feedComponent.props;
            const hasIdProperty = 'id' in props && 'id' in feedComponentProps;
            if (!hasIdProperty) {
              return undefined;
            }
            return props.id != null && props.id === feedComponentProps.id;
          });
          const carouselComponent = componentToReplace
            ? (componentToReplace as CarouselComponent)
            : null;
          if (carouselComponent && state.data) {
            const carousel = state.data[index] as CarouselComponent;
            carousel.props.components = carouselComponent.props.components;
          }
          return feedComponent;
        }),
      };

    default:
      return state;
  }
}
