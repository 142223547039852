import type { FC } from 'react';

import classNames from 'classnames';

import { usePlayer } from '../../MeliPlayer.context';
import { SoundOffIcon } from '../icons/SoundOffIcon';
import { SoundOnIcon } from '../icons/SoundOnIcon';
import { Button, ButtonText } from '../ui/Button';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';

export type MuteButtonProps = {
  className?: string;
};

export const MuteButton: FC<MuteButtonProps> = ({ className }) => {
  const { muteAriaLabel, unmuteAriaLabel, volumeAutoplayUnmuteLabel } =
    useMeliPlayerTranslations();
  const muted = usePlayer(
    ({ muted: playerMuted, volume }) => playerMuted || volume === 0,
  );
  const mute = usePlayer(({ mute: playerMuted }) => playerMuted);
  const unmute = usePlayer(({ unmute: playerUnmute }) => playerUnmute);
  const mutedByAutoplayFallback = usePlayer(
    ({ mutedByAutoplayFallback: playerMutedByAutoplayFallback }) =>
      playerMutedByAutoplayFallback,
  );
  const Icon = muted ? SoundOffIcon : SoundOnIcon;
  const label = muted ? unmuteAriaLabel : muteAriaLabel;

  const handleClick = () => {
    if (muted) {
      unmute();
    } else {
      mute();
    }
  };

  return (
    <Button
      className={classNames('meli-player__mute-button', className)}
      aria-label={label}
      onClick={handleClick}
    >
      <Icon />

      {mutedByAutoplayFallback && (
        <ButtonText>{volumeAutoplayUnmuteLabel}</ButtonText>
      )}
    </Button>
  );
};
