import type {
  BaseEvent,
  PlayerEventCallback,
  PlayerEventExtraData,
  PlayerEventMap,
} from '../../events.types';
import type { IPlayerAdapter } from '../../MeliPlayer.types';

import EventEmitter from 'events';

import { PlayerEvent } from '../../events.types';

export type EventEmitterExtraDataArgs<T extends PlayerEvent> =
  BaseEvent extends PlayerEventMap[T]
    ? [undefined?]
    : [PlayerEventExtraData<T>];

export interface IEventEmitter {
  on: <T extends PlayerEvent>(
    event: T,
    callback: PlayerEventCallback<T>,
  ) => () => void;
  off: <T extends PlayerEvent>(
    event: T,
    callback: PlayerEventCallback<T>,
  ) => void;
  emit: <T extends PlayerEvent>(
    event: T,
    ...data: EventEmitterExtraDataArgs<T>
  ) => void;
  removeAllListeners: () => void;
}

export const eventEmitter = (get: () => IPlayerAdapter) => {
  const emitter = new EventEmitter();

  return {
    on(event, callback) {
      emitter.on(event, callback);

      return () => emitter.off(event, callback);
    },

    off(event, callback) {
      emitter.off(event, callback);
    },

    emit(event, data?) {
      const baseData: Omit<BaseEvent, 'type'> = {
        timestamp: Date.now(),
        player: { get },
      };

      const eventData = {
        ...baseData,
        ...data,
        type: event,
      };

      emitter.emit(event, eventData);
      emitter.emit(PlayerEvent.ALL, eventData);
    },

    removeAllListeners() {
      emitter.removeAllListeners();
    },
  } as IEventEmitter;
};
