import type { RequestNativeEventArgs } from '@meli/mobile-webkit/types/events/native-event';

import { useEffect } from 'react';

import { isWebKit2 } from '../../utils/webkit/check-version';
import { CUSTOM_NATIVE_EVENTS } from '../../utils/webkit/custom-native-events';

const { USER_EVENT } = CUSTOM_NATIVE_EVENTS;

export const onNativeBackEvent = (
  event: CustomEvent<RequestNativeEventArgs<unknown>>,
  onNativeBack: () => void,
) => {
  const { args } = event.detail;

  const isValidArgs = typeof args === 'object' && args !== null;
  const isCustomBackEvent = isValidArgs && 'custom_back' in args;

  if (!isCustomBackEvent) {
    return;
  }

  const { custom_back } = args;

  if (custom_back === 'false' || custom_back === false) {
    onNativeBack();
  }
};

// We listen to a custom native event to close the KW modal when the user clicks the native back button (Android)
export const useCustomNativeBackEvent = (onNativeBack: () => void) => {
  useEffect(() => {
    if (isWebKit2()) {
      window.MobileWebKit?.events.subscribe(USER_EVENT, (event) => {
        onNativeBackEvent(event, onNativeBack);
      });

      return;
    }

    window?.MobileWebKit?.on('native_request', (event) => {
      // @ts-expect-error: event does not have proper types
      const customBackEvent = event.args?.custom_back;

      if (customBackEvent === 'false' || customBackEvent === false) {
        onNativeBack();
      }
    });
  }, [onNativeBack]);
};
