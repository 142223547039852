import type { Action, ContextState } from './tracks.context.types';

import { TracksActions } from './tracks.context.actions';

export default function tracksReducer(
  state: ContextState,
  action: Action,
): ContextState {
  switch (action.type) {
    case TracksActions.SET_TRACK_FILTER:
      return {
        ...state,
        ableToSendFilterTrack: action.payload.enableFilterTrack,
      };

    default:
      return state;
  }
}
