import type { FC } from 'react';
import type { LoadingOverlayProps } from './LoadingOverlay.type';

import classNames from 'classnames';
import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';

import { Typography } from '../Typography/mobile';

export const LoadingOverlay: FC<LoadingOverlayProps> = ({
  label,
  animateIn = true,
  hide,
  loadingLabel,
  spinner,
}) => (
  <div
    className={classNames('loading-overlay', {
      'loading-overlay--animate-in': animateIn,
      'loading-overlay--hide': hide,
    })}
  >
    <ProgressIndicatorCircular size="medium" {...spinner} />
    <Typography type="body" size="s" {...loadingLabel} label={label} />
  </div>
);
