import type { FC } from 'react';
import type { SnackBarConfig } from '../../../context';
import type { SnackbarProps } from '../../../../../components/Snackbar';

import { useContext, useEffect, useState } from 'react';

import { HubContext } from '../../../context';
import { Snackbar as BaseSnackbar } from '../../../../../components/Snackbar';

export const Snackbar: FC<SnackbarProps> = ({ ...props }) => {
  const { addNewSnackbar, errorAction, getSnackbarById } =
    useContext(HubContext);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [config, setConfig] = useState({});
  const handleShow = (options?: SnackBarConfig) => {
    setShowSnackbar(true);
    setConfig({ ...options });
  };
  const handlerHide = () => setShowSnackbar(false);

  useEffect(() => {
    if (errorAction === props.id) {
      getSnackbarById(props.id)?.showSnackbar();
    }
  }, [errorAction]);

  useEffect(() => {
    if (props.id) {
      addNewSnackbar(props.id, handleShow, handlerHide);
    }
  }, []);

  return (
    <BaseSnackbar
      show={showSnackbar}
      action={props.action}
      {...props}
      {...config}
    />
  );
};
