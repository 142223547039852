import type { FC } from 'react';
import type { ProviderProps } from './shared.context.types';

import { createContext, useReducer, useMemo } from 'react';

import sharedReducer from './shared.context.reducer';

const initialState: {
  params?: Record<string, string>;
  headers?: {
    'x-middleend-version'?: string;
    'x-playback-history-version'?: string;
  };
} = {};

export const SharedContext = createContext(initialState);

export const SharedProvider: FC<ProviderProps> = ({ children, ...props }) => {
  const [state] = useReducer(sharedReducer, props);

  const value = useMemo(() => state, [state]);

  return (
    <SharedContext.Provider value={value}>{children}</SharedContext.Provider>
  );
};
