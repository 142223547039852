import { useState } from 'react';

import classNames from 'classnames';

import { VolumeBar } from './VolumeBar';
import { MuteButton } from './MuteButton';
import { usePlayer } from '../../MeliPlayer.context';

export const VolumeControl = () => {
  const [isDragging, setIsDragging] = useState(false);
  const mutedByAutoplayFallback = usePlayer(
    ({ mutedByAutoplayFallback: playerMutedByAutoplayFallback }) =>
      playerMutedByAutoplayFallback,
  );

  return (
    <div
      className={classNames('meli-player__volume-control', {
        'meli-player__volume-control--dragging': isDragging,
      })}
    >
      <MuteButton />

      {!mutedByAutoplayFallback && (
        <VolumeBar onDraggingChange={setIsDragging} />
      )}
    </div>
  );
};
