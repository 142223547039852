import type { AdapterStore } from './adapters/adapters.types';
import type { IPlayerAdapter } from './MeliPlayer.types';

import { createContext, useContext } from 'react';

export const PlayerContext = createContext({} as AdapterStore);

export function usePlayer<T>(selector: (state: IPlayerAdapter) => T): T {
  const usePlayerState = useContext(PlayerContext);

  return usePlayerState(selector);
}
