import type { PillProps } from '../../../Pill/Pill.types';
import type { ImageProps } from '../../../Image/Image.types';
import type { Tracks } from '../../../../utils/track';
import type { PlayBackContext } from '../MediaCardVideoPlayer';
import type { PillTagProps } from '../../../PillTag/PillTag.types';
import type { KnownComponent } from '../../../../../types/components';

type TopLeftItem = {
  type: 'pill';
  props: PillProps;
};

type TopRightItem = {
  type: 'tag';
  props: PillTagProps;
};

export enum HeaderType {
  ACTIVE = 'active',
  DEFAULT = 'default',
}

type UiPlayer = {
  finishedOverlay: {
    buttonLabel: string;
  };
  topLeftItem?: TopLeftItem;
  topRightItem?: TopRightItem;
  controls?: Array<KnownComponent>;
};

export type HeaderBackgroundDefaultProps = {
  background: {
    type: 'image';
    props: ImageProps;
  };

  topLeftItem?: TopLeftItem;
  topRightItem?: TopRightItem;

  bottomLeftItem?: {
    type: 'pill';
    props: PillProps;
  };
};

export type HeaderBackgroundActiveProps = {
  background:
    | {
        type: 'card-video' | 'player';
        props: {
          playbackContext: PlayBackContext;
          ui: UiPlayer;
        };
        tracks?: Tracks;
      }
    | {
        type: 'image';
        props: ImageProps;
        tracks?: Tracks;
      };

  progress?: {
    type: 'progress';
    props: {
      value: number;
    };
  };
};

export type HeaderProps = {
  currentHeader: HeaderType;
  header: {
    default: HeaderBackgroundDefaultProps;
    active?: HeaderBackgroundActiveProps;
  };
  onCanPlay?: () => void;
  preloadVideo?: boolean;
};
