import type { FC } from 'react';
import type {
  BottomLeft,
  BottomLeftItemProps,
  BottomLeftItemsProps,
} from '../MediaCardCompact.types';
import type { TypographyApiProps } from '../../Typography/mobile';
import type { IconProps } from '../../Icon/Icon.type';
import type { ComponentMapperProps } from '../../ComponentMapper';

import isEmpty from 'lodash/isEmpty';

import { Typography } from '../../Typography/mobile';
import { Icon } from '../../Icon';
import { TypeKnownComponent } from '../../../../types/components';
import { ComponentMapper } from '../../ComponentMapper';

const componentsList: ComponentMapperProps['components'] = {
  [TypeKnownComponent.TYPOGRAPHY]: (props: TypographyApiProps) => (
    <Typography
      maxLines={1}
      size="xs"
      type="body"
      color="inverted"
      {...props}
    />
  ),
  [TypeKnownComponent.ICON]: (props: IconProps) => (
    <Icon className="mediacard-compact__icon-play--bottom-left" {...props} />
  ),
};

const Item: FC<BottomLeftItemProps> = ({ props }) => {
  if (isEmpty(props.label)) {
    return null;
  }

  return (
    <div className="mediacard-compact__text--bottom-left">
      <Typography
        maxLines={1}
        size="xs"
        type="body"
        color="inverted"
        {...props}
      />
    </div>
  );
};

const Items: FC<{ items: BottomLeftItemsProps }> = ({ items }) => (
  <div className="mediacard-compact__text--bottom-left">
    {items.map((item, index) => {
      const key = `${item.type}-${index}`;

      return (
        <ComponentMapper key={key} {...item} components={componentsList} />
      );
    })}
  </div>
);

export const BottomLeftWrapper: FC<BottomLeft> = ({
  isVisible,
  item,
  items,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <>
      {item && <Item {...item} />}
      {items && <Items items={items} />}
    </>
  );
};
