import type { FC } from 'react';
import type { AudioTrack, SubtitleTrack } from '../../events.types';

import { useEffect, useState } from 'react';

import classNames from 'classnames';

import { usePlayer } from '../../MeliPlayer.context';
import { CheckMarkIcon } from '../icons/CheckMarkIcon';
import { CloseIcon } from '../icons/CloseIcon';
import { ScrollContainer } from '../ui/ScrollContainer';
import { useMeliPlayerTranslations } from '../../MeliPlayerTranslations.context';

export type SettingsOverlayProps = {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  onAudioTrackClick?: (audioTrack: AudioTrack) => void;
  onSubtitleTrackClick?: (subtitleTrack?: SubtitleTrack) => void;
  onDisableSubtitlesClick?: () => void;
};

export const SettingsOverlay: FC<SettingsOverlayProps> = ({
  visible,
  onVisibleChange,
  onAudioTrackClick,
  onSubtitleTrackClick,
  onDisableSubtitlesClick,
}) => {
  const {
    audioAriaLabel,
    availableAudiosTitle,
    availableSubtitlesTitle,
    closeAriaLabel,
    disableSubtitle,
    subtitlesAriaLabel,
  } = useMeliPlayerTranslations();
  const subtitleTracks = usePlayer(
    ({ subtitleTracks: playerSubtitleTracks }) => playerSubtitleTracks,
  );
  const currentAudioId = usePlayer(
    ({ currentAudioId: playerCurrentAudioId }) => playerCurrentAudioId,
  );
  const currentSubtitleId = usePlayer(
    ({ currentSubtitleId: playerCurrentSubtitleId }) => playerCurrentSubtitleId,
  );
  const audioTracks = usePlayer(
    ({ audioTracks: playerAudioTracks }) => playerAudioTracks,
  );
  const enableSubtitle = usePlayer(
    ({ enableSubtitle: playerEnableSubtitle }) => playerEnableSubtitle,
  );
  const disableSubtitles = usePlayer(
    ({ disableSubtitles: playerDisableSubtitles }) => playerDisableSubtitles,
  );
  const enableAudio = usePlayer(
    ({ enableAudio: playerEnableAudio }) => playerEnableAudio,
  );
  const containerNode = usePlayer(
    ({ containerNode: playerContainerNode }) => playerContainerNode,
  );
  const [isCompact, setIsCompact] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const newCompact = entries[0].contentRect.width < 540;

      if (newCompact !== isCompact) {
        setIsCompact(newCompact);
      }
    });

    if (containerNode.current) {
      observer.observe(containerNode.current);
    }

    return () => observer.disconnect();
  }, [isCompact]);

  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onVisibleChange(false);
      }
    };

    if (visible) {
      window.addEventListener('keyup', callback);
    }

    return () => window.removeEventListener('keyup', callback);
  }, [visible]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames('meli-player__settings', {
        'meli-player__settings--visible': visible,
      })}
      onClick={() => onVisibleChange(false)}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames('meli-player__settings_overlay', {
          'meli-player__settings_overlay--compact': isCompact,
        })}
        onClick={(event) => event.stopPropagation()}
      >
        <button
          type="button"
          onClick={() => onVisibleChange(false)}
          className="meli-player__settings-close"
          aria-label={closeAriaLabel}
        >
          <CloseIcon />
        </button>

        <div className="meli-player__settings_row meli-player__settings_titles">
          <h2>{availableAudiosTitle}</h2>
          <h2>{availableSubtitlesTitle}</h2>
        </div>

        <ScrollContainer className="meli-player__settings_scroll">
          <div className="meli-player__settings_row meli-player__settings_tracks">
            <ul aria-label={audioAriaLabel}>
              {audioTracks.map((audio) => (
                <li key={audio.id}>
                  <button
                    type="button"
                    disabled={audio.id === currentAudioId}
                    className={classNames('meli-player__settings-track', {
                      'meli-player__settings-track--active':
                        audio.id === currentAudioId,
                    })}
                    onClick={() => {
                      enableAudio(audio.id);
                      onAudioTrackClick?.(audio);
                    }}
                  >
                    <span>{audio.label}</span>
                    {audio.id === currentAudioId && <CheckMarkIcon />}
                  </button>
                </li>
              ))}
            </ul>

            <ul aria-label={subtitlesAriaLabel}>
              <li>
                <button
                  type="button"
                  disabled={!currentSubtitleId}
                  className={classNames('meli-player__settings-track', {
                    'meli-player__settings-track--active': !currentSubtitleId,
                  })}
                  onClick={() => {
                    disableSubtitles();
                    onDisableSubtitlesClick?.();
                    onSubtitleTrackClick?.();
                  }}
                >
                  <span>{disableSubtitle}</span>
                  {!currentSubtitleId && <CheckMarkIcon />}
                </button>
              </li>

              {subtitleTracks.map((subtitle) => (
                <li key={subtitle.id}>
                  <button
                    type="button"
                    disabled={subtitle.id === currentSubtitleId}
                    className={classNames('meli-player__settings-track', {
                      'meli-player__settings-track--active':
                        subtitle.id === currentSubtitleId,
                    })}
                    onClick={() => {
                      enableSubtitle(subtitle.id);
                      onSubtitleTrackClick?.(subtitle);
                    }}
                  >
                    <span>{subtitle.label}</span>
                    {subtitle.id === currentSubtitleId && <CheckMarkIcon />}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </ScrollContainer>
      </div>
    </div>
  );
};
