import type { FC } from 'react';
import type { SliderStateOptions } from '@react-stately/slider';

import { useCallback, useRef } from 'react';

import { useSlider } from '@react-aria/slider';
import { useSliderState } from '@react-stately/slider';
import classNames from 'classnames';

import { usePlayer } from '../../MeliPlayer.context';
import { SliderThumb } from './SliderThumb';

export type SliderProps = {
  minValue: number;
  maxValue: number;
  value: number;
  secondaryValue?: number;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onChangeEnd?: (value: Array<number> | number) => void;
  onChange: (value: Array<number> | number) => void;
  label: string;
  className?: string;
  disabled?: boolean;
};

export const Slider: FC<SliderProps> = (props) => {
  const {
    secondaryValue,
    value,
    maxValue,
    label,
    className,
    disabled,
    onDragStart,
    onDragEnd,
  } = props;
  const trackRef = useRef<HTMLDivElement>(null);
  const sliderProps: Partial<SliderStateOptions<Array<number> | number>> = {
    ...props,
    isDisabled: disabled,
    value,
  };

  const state = useSliderState({
    ...sliderProps,
    numberFormatter: new Intl.NumberFormat(),
  });

  const { groupProps, trackProps } = useSlider(sliderProps, state, trackRef);
  const setCanHideControls = usePlayer(
    ({ setCanHideControls: playerSetCanHideControls }) =>
      playerSetCanHideControls,
  );

  const handleDragStart = useCallback(() => {
    setCanHideControls(false);
    onDragStart?.();
  }, []);

  const handleDragEnd = useCallback(() => {
    setCanHideControls(true);
    onDragEnd?.();
  }, []);

  return (
    <div
      {...groupProps}
      className={classNames('meli-player__slider', className, {
        'meli-player__slider--disabled': state.isDisabled,
        'meli-player__slider--dragging': state.isThumbDragging(0),
      })}
    >
      <div
        {...trackProps}
        ref={trackRef}
        className="meli-player__slider__track"
      >
        <div
          className="meli-player__slider__progress meli-player__slider__progress--secondary"
          style={{ width: `${((secondaryValue ?? 0) * 100) / maxValue}%` }}
        />

        <div
          className="meli-player__slider__progress meli-player__slider__progress--primary"
          style={{ width: `${state.getThumbPercent(0) * 100}%` }}
        />

        {!disabled && (
          <SliderThumb
            index={0}
            state={state}
            trackRef={trackRef}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            label={label}
          />
        )}
      </div>
    </div>
  );
};
